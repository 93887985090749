<template>
  <div class="new-business-hero-form">
    <div class="success" :class="{ active: success }">
      <p class="semi-bold">Thank you for your nomination!</p>
      <p>
        If you’d like to nominate<br />someone else please
        <span class="reset semi-bold pink" @click="$emit('update:success', false)">
          click here
        </span>
      </p>
      <div class="button-grid">
        <div class="button">
          submitted
        </div>
      </div>
    </div>
    <form ref="form" class="form-container" :class="{ active: !success }" @submit.prevent="submit">
      <div class="title">Nominate {{ months[now.getMonth()] }}’s New Business Hero of the Month here</div>

      <div class="form-grid">
        <div class="form">
          <input
            class="input text"
            name="Nominated"
            placeholder="name of nominee"
            type="text"
            v-model="nominated"
            :class="{ error: errors.indexOf('nominated-required') != -1 }"
          />
          <input
            class="input text"
            name="Market"
            placeholder="nominee’s market"
            type="text"
            v-model="market"
            :class="{ error: errors.indexOf('market-required') != -1 }"
          />
          <input
            class="input text"
            name="NominatedBy"
            placeholder="your name"
            type="text"
            v-model="nominatedBy"
            :class="{ error: errors.indexOf('nominatedBy-required') != -1 }"
          />
          <div class="details" :class="{ error: errors.indexOf('details-required') != -1 }">
            <label class="label">reason for nomination</label>
            <textarea class="input textarea" name="Details" v-model="details" />
            <div class="count" :class="{ error: errors.indexOf('details-max-length') != -1 }">
              <span ref="count">{{ details.length }}</span
              >/{{ max }}
            </div>
          </div>
          <input type="hidden" name="Date" :value="new Date().toLocaleString()" />
        </div>
      </div>
      <div class="button-grid">
        <button class="button" type="submit">
          submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'NewBusinessHeroForm',

  props: {
    success: Boolean,
  },

  data: () => ({
    details: '',
    errors: [],
    market: '',
    max: 350,
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    nominated: '',
    nominatedBy: '',
    now: new Date(),
  }),

  methods: {
    async submit() {
      if (!this.details) {
        this.errors.push('details-required')
      }

      if (this.details.length > this.max) {
        this.errors.push('details-max-length')
      }

      if (!this.market) {
        this.errors.push('market-required')
      }

      if (!this.nominated) {
        this.errors.push('nominated-required')
      }

      if (!this.nominatedBy) {
        this.errors.push('nominatedBy-required')
      }

      setTimeout(() => {
        this.errors = []
      }, 900)

      if (!this.errors.length) {
        const formData = new FormData(this.$refs.form),
          formDataObject = {}

        formData.forEach((value, key) => (formDataObject[key] = value))

        await fetch(
          'https://prod-06.westeurope.logic.azure.com/workflows/348bddfaa8eb44908702b7631bb1b450/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=HPH-i8ajMgz_5hmT3rmQkHL5DObDmUcAbd5SXfcIFdU',
          {
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formDataObject),
            method: 'POST',
          },
        )

        this.$emit('update:success', true)

        this.details = ''
        this.market = ''
        this.nominated = ''
        this.nominatedBy = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cross-fade-enter-active,
.cross-fade-leave-active {
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1);
}

.cross-fade-enter,
.cross-fade-leave-to {
  opacity: 0;
}

.new-business-hero-form {
  background-color: #fff;
  border-radius: 32px;
  position: relative;
}

.form-grid {
  padding: 1px 1rem;

  @include for-tablet-landscape-up {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 0;
  }
}

.form {
  grid-column: 2/2;

  .title {
    display: none;
    font-size: 21px;
    font-weight: 600;
    margin: 0 1rem 1.5rem;
    padding-top: 2rem;
    text-align: center;

    @include for-tablet-landscape-up {
      display: block;
    }
  }

  .label {
    color: #0d1726;
    display: block;
    font: 200 19px Poppins;
    text-align: center;
    transition: all 100ms cubic-bezier(0.8, 0, 0.2, 1);
    width: 100%;
  }

  .input {
    background: transparent;
    border: none;
    color: #0d1726;
    display: block;
    font: 200 19px Poppins;
    outline: none;
    text-align: center;
    transition: all 100ms cubic-bezier(0.8, 0, 0.2, 1);
    width: 100%;

    &::placeholder {
      color: #0d1726;
      opacity: 1;
    }

    &.text {
      border-bottom: 1px solid #0d1726;
      border-radius: 0;
      margin: 2rem 0;
    }

    &.textarea {
      background-color: #edf2f4;
      border-radius: 15px;
      min-height: 180px;
      margin: 1rem 0 4rem;
      resize: none;
    }
  }

  .input.error,
  .count.error,
  .error .label {
    animation-duration: 600ms;
    animation-name: headShake;
    animation-timing-function: ease-in-out;
    border-color: #dc3545;
    color: #dc3545;

    &::placeholder {
      color: #dc3545;
    }
  }
}

.button-grid {
  bottom: -1rem;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  position: absolute;
  width: 100%;

  @include for-tablet-landscape-up {
    bottom: -1.5rem;
  }

  .button {
    background-color: #fd3e81;
    border: none;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 600;
    grid-column: 2/2;
    line-height: 1.15;
    outline: none;
    padding: 0.5rem;
    width: 100%;

    @include for-tablet-landscape-up {
      font-size: 30px;
    }
  }
}

.details {
  position: relative;

  .count {
    bottom: 10px;
    color: #fd3e81;
    font-size: 12px;
    font-weight: 200;
    position: absolute;
    right: 10px;
  }
}

.reset {
  cursor: pointer;
}

.form-container {
  position: relative;

  .button {
    cursor: pointer;
  }
}

.success {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 1rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;

  p {
    font-size: 21px;
  }

  .button {
    background-color: #61e1e0;
  }
}

.form-container,
.success {
  opacity: 0;
  height: 100%;
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1);

  &.active {
    opacity: 1;
    z-index: 1;
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}
</style>
