<template>
  <div class="overflow" :style="{ transform: `scaleY(${scale})` }">
    <div
      ref="badge"
      :class="`badge ${size == 'small' ? 'small' : 'large'} ${renderQuarter}`"
      :style="{ transform: `scaleX(${scale})` }"
    >
      <svg class="label" ref="label">
        <path
          v-if="size == 'small'"
          d="M 0, 105.625 a 105.625,105.625 0 1,1 211.25,0"
          fill="none"
          :id="`curve-${_uid}`"
        />
        <path v-else d="M 0, 162.5 a 162.5,162.5 0 1,1 325,0" fill="none" :id="`curve-${_uid}`" />
        <text>
          <textPath :xlink:href="`#curve-${_uid}`">
            {{ label }}
          </textPath>
        </text>
      </svg>
      <div class="circle-rings" ref="circleRings" />
      <img class="icon" ref="icon" :src="renderBadge" :class="{ small: size == 'small' }" />
      <div class="plaque" ref="plaque">
        <div class="country" ref="country" v-html="renderCountry" />
      </div>
    </div>
  </div>
</template>

<script>
import fitty from 'fitty'
import mojs from '@mojs/core'
import BreakpointMixin from '@/mixins/BreakpointMixin'

export default {
  name: 'Badge',

  mixins: [BreakpointMixin],

  props: {
    activeQuarter: [Number, String],
    animationCount: Number,
    countries: Array,
    currentQuarter: Number,
    delay: Number,
    loading: Boolean,
    size: String,
    type: String,
  },

  data: () => ({
    countryTimeline: null,
    enterTimeline: null,
    leaveTimeline: null,
    renderBadge: null,
    renderCountry: '&nbsp;',
    renderQuarter: 'current',
    scale: 1,
  }),

  computed: {
    burstChildrenRadius() {
      return this.size == 'small' ? 2 : 4
    },

    burstRadiusDelta() {
      return this.size == 'small' ? { 65: 125 } : { 90: 200 }
    },

    circleCoreRadius() {
      return this.size == 'small' ? 60 : 80
    },

    ringStrokeWidth() {
      return this.size == 'small' ? 18 : 25
    },

    innerRingRadius() {
      return this.size == 'small' ? 69 : 92.5
    },

    middleRingRadius() {
      return this.size == 'small' ? 87 : 117.5
    },

    label() {
      const prefix = this.renderQuarter == 'last' ? '' : 'Current '

      if (this.type == 'pitch') {
        return `${prefix}Best Pitch Performer`
      } else if (this.type == 'prospect') {
        return `${prefix}Best Prospector`
      }

      return 'Leading Market'
    },

    labelTop() {
      return this.size == 'small' ? '35%' : '5%'
    },

    labelRotationDeltaForward() {
      return this.size == 'small' ? { [-20]: 5 } : { 0: 25 }
    },

    labelRotationDeltaBackward() {
      return this.size == 'small' ? { 5: [-20] } : { 25: 0 }
    },

    ringTop() {
      return this.size == 'small' ? '70%' : '60%'
    },

    starChildrenRadius() {
      return this.size == 'small' ? 6 : 12
    },
  },

  mounted() {
    window.addEventListener('resize', this.resizeHandler)

    // mo.js setup
    const accelerateCurve = mojs.easing.bezier(0.7, 0, 1, 0.5),
      decelerateCurve = mojs.easing.bezier(0.1, 0.9, 0.2, 1)

    class Star extends mojs.CustomShape {
      getShape() {
        return '<path d="M5.51132201,34.7776271 L33.703781,32.8220808 L44.4592855,6.74813038 C45.4370587,4.30369752 47.7185293,3 50,3 C52.2814707,3 54.5629413,4.30369752 55.5407145,6.74813038 L66.296219,32.8220808 L94.488678,34.7776271 C99.7034681,35.1035515 101.984939,41.7850013 97.910884,45.2072073 L75.9109883,63.1330483 L82.5924381,90.3477341 C83.407249,94.4217888 80.4739296,97.6810326 77.0517236,97.6810326 C76.0739505,97.6810326 74.9332151,97.3551083 73.955442,96.7032595 L49.8370378,81.8737002 L26.044558,96.7032595 C25.0667849,97.3551083 23.9260495,97.6810326 22.9482764,97.6810326 C19.3631082,97.6810326 16.2668266,94.4217888 17.4075619,90.3477341 L23.9260495,63.2960105 L2.08911601,45.2072073 C-1.98493875,41.7850013 0.296531918,35.1035515 5.51132201,34.7776271 Z" />'
      }
    }
    mojs.addShape('star', Star)

    this.countryTimeline = new mojs.Timeline()

    this.enterTimeline = new mojs.Timeline({
      delay: this.delay,
      onComplete: () => {
        this.$emit('update:animationCount', this.animationCount - 1)

        if (this.countries.length > 1) {
          this.countryTimeline.play()
        }
      },
    })

    this.leaveTimeline = new mojs.Timeline({
      onStart: () => {
        this.countryTimeline.stop()
      },
      onComplete: () => {
        setTimeout(() => {
          // Reset: Circle core and rings
          setTimeout(() => {
            new mojs.Html({
              el: this.$refs.circleRings,
              scale: 1,
              y: 0,
            })
          }, 100)

          if (this.activeQuarter == this.currentQuarter - 1) {
            this.renderQuarter = 'last'
          } else if (this.activeQuarter == this.currentQuarter) {
            this.renderQuarter = 'current'
          } else {
            this.renderQuarter = 'annual'
          }

          this.renderBadge = require(`@/assets/images/${this.renderQuarter}-badge-${this.type}.svg`)
          this.renderCountry = this.countries[0] || '-'

          this.enterTimeline.play()
        }, 1000)
      },
    })

    // fitty setup
    fitty(this.$refs.plaque, {
      maxSize: 28,
    })

    // Multiple country timeline
    this.countryTimeline.add(
      new mojs.Html({
        delay: 150,
        duration: 1600,
        easing: accelerateCurve,
        el: this.$refs.country,
        isYoyo: true,
        repeat: 999,
        y: { 0: 45 },
        onRepeatComplete: (isForward, isYoyo) => {
          if (!isYoyo) {
            this.countries.unshift(this.countries.pop())
            // this.setCountryWidth(this.countries[0])
            this.renderCountry = this.countries[0]
          }
        },
      }),
    )

    // Leave: Circle core and rings
    this.leaveTimeline.add(
      new mojs.Html({
        duration: 800,
        easing: 'back.in',
        el: this.$refs.circleRings,
        scale: { 1: 0 },
        y: { 0: 50 },
      }),
    )

    // Leave: Icon
    this.leaveTimeline.add(
      new mojs.Html({
        angleZ: { 0: -45 },
        duration: 800,
        easing: 'back.in',
        el: this.$refs.icon,
        scale: { 0.9: 0 },
        y: { 0: 50 },
      }),
    )

    // Leave: Label
    this.leaveTimeline.add(
      new mojs.Html({
        angleZ: this.labelRotationDeltaBackward,
        duration: 800,
        easing: 'back.in',
        el: this.$refs.label,
        opacity: { 1: 0 },
        scale: { 1: 0 },
        top: this.labelTop,
        y: { 0: 50 },
      }),
    )

    // Leave: Country
    this.leaveTimeline.add(
      new mojs.Html({
        duration: 800,
        easing: accelerateCurve,
        el: this.$refs.country,
        y: { 0: 45 },
      }),
    )

    // Enter: Inner ring
    this.enterTimeline.add(
      new mojs.Shape({
        angle: 90,
        className: 'stroke',
        delay: 200,
        duration: 2400,
        fill: 'none',
        opacity: 0.6,
        parent: this.$refs.circleRings,
        radius: this.innerRingRadius,
        shape: 'circle',
        strokeDasharray: this.getDashValue(this.innerRingRadius, 100),
        strokeDashoffset: { [this.getDashValue(this.innerRingRadius, 100)]: 0, easing: decelerateCurve },
        strokeWidth: this.ringStrokeWidth,
        top: this.ringTop,
      }),
    )

    // Enter: Middle ring
    this.enterTimeline.add(
      new mojs.Shape({
        angle: 90,
        className: 'stroke',
        delay: 300,
        duration: 2400,
        fill: 'none',
        opacity: 0.4,
        parent: this.$refs.circleRings,
        radius: this.middleRingRadius,
        shape: 'circle',
        strokeDasharray: this.getDashValue(this.middleRingRadius, 100),
        strokeDashoffset: { [this.getDashValue(this.middleRingRadius, 100)]: 0, easing: decelerateCurve },
        strokeWidth: this.ringStrokeWidth,
        top: this.ringTop,
      }),
    )

    // Enter: Outer ring
    if (this.size != 'small') {
      this.enterTimeline.add(
        new mojs.Shape({
          angle: 90,
          className: 'stroke',
          delay: 400,
          duration: 2400,
          fill: 'none',
          opacity: 0.2,
          parent: this.$refs.circleRings,
          radius: 142.5,
          shape: 'circle',
          strokeDasharray: this.getDashValue(142.5, 100),
          strokeDashoffset: { [this.getDashValue(142.5, 100)]: 0, easing: decelerateCurve },
          strokeWidth: this.ringStrokeWidth,
          top: this.ringTop,
        }),
      )
    }

    // Enter: Burst
    for (var delay = 400; delay <= 700; delay += 150) {
      this.enterTimeline.add(
        new mojs.Burst({
          children: {
            className: 'fill',
            radius: this.burstChildrenRadius,
            opacity: { 1: 0 },
          },
          count: 24,
          parent: this.$refs.badge,
          radius: this.burstRadiusDelta,
          timeline: {
            easing: decelerateCurve,
            delay: delay,
            speed: 0.5,
          },
          top: this.ringTop,
        }),
      )
    }

    // Enter: Stars
    for (var i = 0; i <= 1; i++) {
      this.enterTimeline.add(
        new mojs.Burst({
          children: {
            angle: { 90: 0 },
            degreeShift: 'rand(-50, 50)',
            fill: i ? '#FD3E81' : '#FEDB61',
            radius: this.starChildrenRadius,
            opacity: { 1: 0 },
            shape: 'star',
          },
          className: 'stars',
          count: 4,
          parent: this.$refs.badge,
          radius: this.burstRadiusDelta,
          timeline: {
            easing: decelerateCurve,
            delay: delay,
            speed: 0.5,
          },
          top: this.ringTop,
        }),
      )
    }

    // Enter: Circle core
    this.enterTimeline.add(
      new mojs.Shape({
        duration: 300,
        className: 'fill',
        parent: this.$refs.circleRings,
        radius: { 0: this.circleCoreRadius, easing: decelerateCurve },
        shape: 'circle',
        top: this.ringTop,
      }),
    )

    // Enter: Icon
    this.enterTimeline.add(
      new mojs.Html({
        angleZ: { [-45]: 0 },
        delay: 800,
        duration: 1200,
        easing: 'elastic.out',
        el: this.$refs.icon,
        scale: { 0: 0.9 },
      }),
    )

    // Enter: Label
    this.enterTimeline.add(
      new mojs.Html({
        angleZ: this.labelRotationDeltaForward,
        delay: 1600,
        duration: 2400,
        easing: decelerateCurve,
        el: this.$refs.label,
        opacity: { 0: 1 },
        top: this.labelTop,
      }),
    )

    // Enter: Country
    this.enterTimeline.add(
      new mojs.Html({
        delay: 800,
        duration: 3200,
        easing: decelerateCurve,
        el: this.$refs.country,
        y: { 45: 0 },
      }),
    )
  },

  methods: {
    getDashValue(radius, percentage) {
      const circumference = 2 * 3.1415927 * radius,
        percentageAsDecimal = percentage / 100

      return circumference * percentageAsDecimal
    },

    // setCountryWidth(string) {
    //   const canvas = document.createElement('canvas'),
    //         context = canvas.getContext('2d')

    //   context.font = getComputedStyle(this.$refs.country).font

    //   console.log(
    //     this.$refs.country.getBoundingClientRect(),
    //     Math.ceil(context.measureText(string).width) + 'px'
    //   )
    // },

    resizeHandler() {
      const scale = Math.min(1, this.$refs.badge.clientWidth / 340) // natural width

      this.scale = scale * (this.size == 'small' ? 1.5 : 1)

      if (this.forTabletPortaitOnly()) {
        this.scale *= 0.75
      }
    },
  },

  watch: {
    activeQuarter() {
      this.leaveTimeline.play()
      this.$emit('update:animationCount', this.animationCount + 1)
    },

    loading() {
      this.renderBadge = require(`@/assets/images/${this.renderQuarter}-badge-${this.type}.svg`)
      this.renderCountry = this.countries[0] || '-'

      this.enterTimeline.play()
      this.$emit('update:animationCount', this.animationCount + 1)

      this.resizeHandler()
    },
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
}
</script>

<style lang="scss">
.fill svg ellipse {
  fill: #61e1e0;
}

.stroke svg ellipse {
  stroke: #61e1e0;
}

.badge.last {
  .fill svg ellipse {
    fill: #faa916;
  }

  .stroke svg ellipse {
    stroke: #faa916;
  }
}

.badge.current {
  .stars {
    display: none;
  }
}

.badge.annual {
  .fill svg ellipse {
    fill: #792359;
  }

  .stroke svg ellipse {
    stroke: #792359;
  }

  .stars {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.overflow {
  overflow: hidden;
}

.badge {
  align-items: center;
  display: flex;
  height: 300px;
  justify-content: center;
  position: relative;
}

.circle-rings {
  height: 100%;
  position: absolute;
  width: 100%;
}

.icon {
  height: 205px;
  top: 19%;
  position: absolute;
  z-index: 2;
}

.label {
  font-size: 12px;
  height: 325px;
  overflow: visible;
  position: absolute;
  width: 325px;
}

.plaque {
  background-color: #fff;
  border-radius: 26px;
  bottom: 0;
  font-weight: bold;
  max-width: 100%;
  overflow: hidden;
  padding: 5px 50px;
  position: absolute;
  text-align: center;
  z-index: 1;

  .country {
    align-items: center;
    display: flex;
    height: 42px;
    justify-content: center;
  }
}

.small {
  .icon {
    height: 136px;
    top: 40%;
  }

  .label {
    height: 211.25px;
    width: 211.25px;
  }
}

.large {
  .plaque {
    min-width: 250px;
  }
}
</style>
