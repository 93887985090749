<template>
  <div class="loader">
    <svg viewBox="0 0 389.11 178.66">
      <path
        class="cls-1"
        d="m387,165.22H41.06c-22.12,0-40.06-17.93-40.06-40.06v-4c0-22.12,17.93-42.06,40.06-42.06h-.49s147.88,0,147.88,0c22.12,0,40.06-15.93,40.06-38.06h0c0-22.12-17.93-40.06-40.06-40.06h-62.44"
        ref="line"
      />
      <polyline class="cls-1" points="375.26 152.79 387.69 165.22 375.26 177.66" ref="arrow" />
      <path d="m38.68,31.92v-10.34h32.1v10.34h-32.1Zm10.16,33.09V3.51h11.78v61.51h-11.78Z" />
      <path
        d="m92.28,65.92c-3.96,0-7.52-.99-10.7-2.97-3.18-1.98-5.67-4.68-7.46-8.09-1.8-3.42-2.7-7.25-2.7-11.51s.9-8.18,2.7-11.6c1.8-3.42,4.29-6.12,7.46-8.09,3.18-1.98,6.74-2.97,10.7-2.97,3.12,0,5.9.63,8.36,1.89,2.46,1.26,4.42,3.01,5.89,5.26,1.47,2.25,2.26,4.78,2.38,7.6v15.65c-.12,2.88-.91,5.43-2.38,7.64-1.47,2.22-3.43,3.97-5.89,5.26-2.46,1.29-5.25,1.93-8.36,1.93Zm2.16-10.88c3.3,0,5.96-1.09,8-3.28,2.04-2.19,3.06-5.02,3.06-8.5,0-2.28-.47-4.3-1.39-6.07-.93-1.77-2.22-3.15-3.87-4.14-1.65-.99-3.58-1.48-5.8-1.48s-4.06.5-5.71,1.48c-1.65.99-2.94,2.37-3.87,4.14-.93,1.77-1.39,3.79-1.39,6.07s.46,4.39,1.39,6.16c.93,1.77,2.22,3.15,3.87,4.14,1.65.99,3.55,1.48,5.71,1.48Zm10.43,9.98v-11.69l1.89-10.61-1.89-10.43v-10.7h11.69v43.44h-11.69Z"
      />
      <path
        d="m124.74,65.02V.34h11.78v64.68h-11.78Zm27.7,0l-16.82-22.39,16.73-21.04h13.58l-19.6,23.74.45-5.76,20.05,25.45h-14.39Z"
      />
      <path
        d="m189.22,65.92c-4.68,0-8.83-1-12.46-3.01-3.63-2.01-6.47-4.72-8.54-8.14-2.07-3.42-3.1-7.28-3.1-11.6s1.03-8.08,3.1-11.47c2.07-3.39,4.86-6.08,8.36-8.09,3.51-2.01,7.48-3.01,11.92-3.01,4.68,0,8.6,1.03,11.78,3.1,3.18,2.07,5.7,5.02,7.55,8.86l-25.99,25.54-6.39-6.47,23.47-23.02-.36,8.27c-.78-1.98-1.96-3.51-3.55-4.59-1.59-1.08-3.7-1.62-6.34-1.62s-4.71.53-6.56,1.57c-1.86,1.05-3.28,2.5-4.27,4.36-.99,1.86-1.48,3.99-1.48,6.39,0,2.58.54,4.84,1.62,6.79,1.08,1.95,2.59,3.46,4.54,4.54,1.95,1.08,4.24,1.62,6.88,1.62,2.34,0,4.41-.37,6.2-1.12,1.8-.75,3.39-1.87,4.77-3.37l6.92,6.92c-2.22,2.52-4.87,4.41-7.96,5.67-3.09,1.26-6.46,1.89-10.12,1.89Z"
      />
      <path d="m38.68,114.51v-2.43h24.66v2.43h-24.66Zm10.98,38.97v-59.04h2.79v59.04h-2.79Z" />
      <path
        d="m71.08,153.48v-62.64h2.79v62.64h-2.79Zm32.04,0v-24.75c0-4.5-1.29-8.13-3.87-10.89-2.58-2.76-6.03-4.14-10.35-4.14-2.94,0-5.54.65-7.79,1.94-2.25,1.29-4.02,3.05-5.31,5.26-1.29,2.22-1.93,4.8-1.93,7.74l-1.89-1.08c0-3.12.77-5.91,2.29-8.37,1.53-2.46,3.6-4.41,6.21-5.85,2.61-1.44,5.56-2.16,8.87-2.16s6.19.75,8.68,2.25c2.49,1.5,4.42,3.57,5.81,6.21,1.38,2.64,2.07,5.67,2.07,9.09v24.75h-2.79Z"
      />
      <path
        d="m136.15,154.38c-4.14,0-7.85-.95-11.11-2.83-3.27-1.89-5.85-4.47-7.74-7.74-1.89-3.27-2.83-6.98-2.83-11.12s.91-7.74,2.75-10.98c1.83-3.24,4.35-5.81,7.56-7.7,3.21-1.89,6.82-2.83,10.84-2.83,3.72,0,7,.83,9.85,2.47,2.85,1.65,5.08,3.95,6.71,6.88,1.62,2.94,2.43,6.33,2.43,10.17,0,.24-.02.62-.04,1.12-.03.51-.11,1.16-.23,1.93h-37.89v-2.61h36.09l-.81.63c.24-3.54-.3-6.66-1.62-9.36-1.32-2.7-3.23-4.82-5.72-6.34-2.49-1.53-5.44-2.3-8.86-2.3-3.6,0-6.77.8-9.5,2.39-2.73,1.59-4.86,3.78-6.39,6.57-1.53,2.79-2.29,6.05-2.29,9.77s.79,7.2,2.39,10.08c1.59,2.88,3.82,5.13,6.71,6.75,2.88,1.62,6.24,2.43,10.08,2.43,2.88,0,5.54-.54,7.97-1.62,2.43-1.08,4.48-2.7,6.17-4.86l1.89,1.89c-1.86,2.22-4.22,3.98-7.06,5.26-2.85,1.29-5.96,1.94-9.31,1.94Z"
      />
      <path d="m177.01,153.48v-65.07h11.79v65.07h-11.79Z" />
      <path
        d="m217.78,154.38c-4.56,0-8.61-.97-12.15-2.93-3.54-1.95-6.34-4.63-8.42-8.05-2.07-3.42-3.1-7.32-3.1-11.7s1-8.17,3.02-11.56c2.01-3.39,4.75-6.09,8.23-8.1,3.48-2.01,7.35-3.01,11.61-3.01s7.9.95,11.11,2.83c3.21,1.89,5.73,4.47,7.56,7.74,1.83,3.27,2.75,6.97,2.75,11.12,0,.78-.04,1.57-.14,2.38-.09.81-.26,1.73-.49,2.75l-36.54.09v-8.82l31.05-.09-4.77,3.69c-.12-2.58-.58-4.74-1.4-6.48-.81-1.74-1.98-3.07-3.51-4-1.53-.93-3.41-1.4-5.62-1.4-2.34,0-4.38.53-6.12,1.58-1.74,1.05-3.08,2.52-4,4.41-.93,1.89-1.4,4.13-1.4,6.71s.49,4.94,1.48,6.88c.99,1.95,2.41,3.45,4.27,4.5,1.86,1.05,4.02,1.58,6.48,1.58,2.22,0,4.23-.37,6.03-1.12,1.8-.75,3.36-1.88,4.68-3.38l6.93,6.93c-2.1,2.46-4.67,4.32-7.69,5.58-3.03,1.26-6.32,1.89-9.86,1.89Z"
      />
      <path
        d="m263.86,154.38c-3.96,0-7.53-.99-10.71-2.97-3.18-1.98-5.67-4.68-7.47-8.1-1.8-3.42-2.7-7.26-2.7-11.52s.9-8.19,2.7-11.61c1.8-3.42,4.29-6.12,7.47-8.1,3.18-1.98,6.75-2.97,10.71-2.97,3.12,0,5.91.63,8.37,1.89,2.46,1.26,4.42,3.02,5.9,5.27,1.47,2.25,2.26,4.79,2.38,7.6v15.66c-.12,2.88-.92,5.43-2.38,7.65-1.47,2.22-3.44,3.98-5.9,5.26-2.46,1.29-5.25,1.94-8.37,1.94Zm2.16-10.89c3.3,0,5.97-1.09,8.01-3.29,2.04-2.19,3.06-5.02,3.06-8.5,0-2.28-.47-4.3-1.4-6.08-.93-1.77-2.22-3.15-3.87-4.14-1.65-.99-3.59-1.49-5.81-1.49s-4.07.5-5.71,1.49c-1.65.99-2.94,2.37-3.87,4.14-.93,1.77-1.4,3.8-1.4,6.08s.46,4.4,1.4,6.17c.93,1.77,2.22,3.15,3.87,4.14,1.65.99,3.55,1.48,5.71,1.48Zm10.44,9.99v-11.7l1.89-10.62-1.89-10.44v-10.71h11.7v43.47h-11.7Z"
      />
      <path
        d="m314.71,154.38c-4.02,0-7.62-.99-10.8-2.97-3.18-1.98-5.69-4.68-7.51-8.1-1.83-3.42-2.75-7.26-2.75-11.52s.91-8.19,2.75-11.61c1.83-3.42,4.32-6.12,7.47-8.1,3.15-1.98,6.76-2.97,10.85-2.97,3.12,0,5.92.63,8.42,1.89,2.49,1.26,4.5,3.02,6.03,5.27,1.53,2.25,2.35,4.79,2.48,7.6v15.48c-.12,2.82-.93,5.37-2.43,7.65-1.5,2.28-3.51,4.08-6.03,5.4s-5.34,1.98-8.46,1.98Zm1.98-10.89c2.22,0,4.15-.5,5.81-1.48,1.65-.99,2.94-2.37,3.87-4.14.93-1.77,1.4-3.83,1.4-6.17s-.47-4.3-1.4-6.08c-.93-1.77-2.22-3.15-3.87-4.14-1.65-.99-3.56-1.49-5.72-1.49s-4.16.51-5.8,1.53c-1.65,1.02-2.94,2.4-3.87,4.14-.93,1.74-1.4,3.75-1.4,6.03s.46,4.4,1.4,6.17c.93,1.77,2.23,3.15,3.92,4.14,1.68.99,3.57,1.48,5.67,1.48Zm22.14,9.99h-11.7v-11.7l1.89-10.62-1.98-10.44v-32.31h11.79v65.07Z"
      />
    </svg>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'Loader',

  mounted() {
    let tl = gsap.timeline({})

    tl.fromTo(
      this.$refs.line,
      {
        drawSVG: '100% 100%',
      },
      {
        drawSVG: '0% 100%',
        duration: 2,
        ease: 'slow.in',
      },
    )
      .fromTo(
        this.$refs.arrow,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.1,
        },
      )
      .fromTo(
        this.$refs.arrow,
        {
          drawSVG: '50% 50%',
        },
        {
          drawSVG: '0% 100%',
          duration: 1.5,
          ease: 'slow.inOut',
        },
        '-=.4',
      )
  },
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: none;
  stroke: #011627;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
</style>
