<template>
  <div class="nominate">
    <Header />
    <div class="nominate-grid container">
      <div class="about">
        <NewBusinessHeroBadge
          :delay="1000"
          :success="success"
        />
        <p>Nominate one person who, you consider, has made an outstanding contribution in new business. You can also nominate a unique partnership that you have developed either with another WPP agency or another story that made you win that particular pitch and/or prospect.</p>
        <p>Ensure you submit your entries before the last week of the month to be considered.</p>
      </div>
      <div class="form">
        <NewBusinessHeroForm
          :success="success"
          @update:success="success = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import NewBusinessHeroBadge from '@/components/NewBusinessHeroBadge.vue'
import NewBusinessHeroForm from '@/components/NewBusinessHeroForm.vue'

export default {
  name: 'Nominate',

  components: {
    Header,
    NewBusinessHeroBadge,
    NewBusinessHeroForm,
  },

  data: () => ({
    success: false,
  }),
}
</script>

<style lang="scss" scoped>
.nominate {
  background-image: url(../assets/images/background-nominate.png);
  background-position: 50% 0;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 4rem;
}

.nominate-grid {
  @include for-tablet-landscape-up {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 3fr 1fr 6fr 1fr;
  }
}

.about {
  grid-column: 2/2;
  text-align: center;
}

.form {
  grid-column: 4/4;
}
</style>