<template>
  <vue-custom-scrollbar class="leaderboard" ref="leaderboard">
    <transition name="fade">
      <Loader v-if="loading" />
    </transition>
    <div class="leaderboard-grid container" :class="{ loading }">
      <div class="footer">
        <a
          href="https://insidemedia.sharepoint.com/sites/McDab/Pages/Index.aspx#/"
          target="_blank"
          @click="$gtag.event('Click', { event_category: 'External link', event_label: 'McDab' })"
        >
          Powered by <span class="semi-bold">MCDab</span>
        </a>
      </div>
      <transition-group
        class="leaderboard-column"
        name="list"
        tag="div"
        @beforeEnter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <LeaderboardEntry
          v-for="(entry, index) in leaderboard[activeQuarter]"
          :currentQuarter="currentQuarter"
          :activeQuarter="activeQuarter"
          :activeRegion="activeRegion"
          :data-index="index"
          :entry="entry"
          :data-key="`${activeQuarter}-${removeWhitespace(entry.Country)}`"
          :key="`${activeQuarter}-${removeWhitespace(entry.Country)}`"
          :ref="`${activeQuarter}-${removeWhitespace(entry.Country)}`"
          :searchResult="searchResult"
        />
      </transition-group>
    </div>
  </vue-custom-scrollbar>
</template>

<script>
import LeaderboardEntry from '@/components/LeaderboardEntry.vue'
import Loader from '@/components/Loader.vue'
import StringMixin from '@/mixins/StringMixin'
import vueCustomScrollbar from 'vue-custom-scrollbar'

import 'vue-custom-scrollbar/dist/vueScrollbar.css'

export default {
  name: 'Leaderboard',

  components: {
    LeaderboardEntry,
    Loader,
    vueCustomScrollbar,
  },

  mixins: [StringMixin],

  data: () => ({
    firstVisibleIndex: 0,
    lastVisibleIndex: 10,
    totalVisibleIndex: 10,
  }),

  props: {
    activeQuarter: [Number, String],
    activeRegion: String,
    animationCount: Number,
    currentQuarter: Number,
    leaderboard: Object,
    loading: Boolean,
    searchResult: String,
  },

  methods: {
    beforeEnter: function(el) {
      el.style.opacity = 0
      el.style.transform = 'translateX(100px)'
    },

    enter: function(el) {
      this.$nextTick(() => {
        const delay =
          parseFloat(getComputedStyle(document.querySelector('.list-enter-active')).transitionDuration) * 1000

        setTimeout(() => {
          // leave transition
          try {
            this.$refs.leaderboard.$el.scroll({
              top: 0,
            })
          } catch (e) {
            console.log(e)
          } // Edge Spartan fix

          setTimeout(() => {
            // stagger
            el.style.opacity = 1
            el.style.transform = 'translateX(0)'

            setTimeout(() => {
              // transition complete
              this.$emit('update:animationCount', this.animationCount - 1)
            }, delay)
          }, this.enterTimeoutInterval(el))
        }, delay)
      })
    },

    enterTimeoutInterval(el) {
      const index = parseInt(el.dataset.index)

      if (index <= this.totalVisibleIndex) {
        return (index + 1) * 150
      }

      return 0
    },

    leave: function(el) {
      setTimeout(() => {
        el.style.opacity = 0
        el.style.transform = 'translateX(-100px)'

        this.$emit('update:animationCount', this.animationCount + 1)
      }, this.leaveTimeoutInterval(el))
    },

    leaveTimeoutInterval(el) {
      const index = parseInt(el.dataset.index)

      if (index >= this.firstVisibleIndex && index <= this.lastVisibleIndex) {
        return (index + 1 - this.firstVisibleIndex) * 150
      }

      return 0
    },
  },

  watch: {
    activeQuarter(newQuarter, oldQuarter) {
      const leaderboardRect = this.$refs.leaderboard.$el.getBoundingClientRect()
      let lastEntryElWasVisible = false

      for (const entry of this.leaderboard[oldQuarter]) {
        const entryEl = this.$refs[`${oldQuarter}-${this.removeWhitespace(entry.Country)}`][0].$el,
          entryRect = entryEl.getBoundingClientRect()

        if (entryRect.bottom > leaderboardRect.top && entryRect.top < leaderboardRect.bottom) {
          if (!lastEntryElWasVisible) {
            this.firstVisibleIndex = parseInt(entryEl.dataset.index)
          }

          lastEntryElWasVisible = true
        } else if (lastEntryElWasVisible) {
          this.lastVisibleIndex = parseInt(entryEl.dataset.index)
          this.totalVisibleIndex = this.lastVisibleIndex - this.firstVisibleIndex

          return
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1) 1500ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  /**
   * The actual animation time is defined in the LeaderboardEntry component.
   * This time defines the gap between destroying the old and creating the new list elements based on:
   * (10 rows visible (on an average screen) × 150ms animation stagger time) + 300ms animation time
   */
  transition: all 1800ms ease, opacity 1200ms ease; /* Accelerate (leave) */
}

.leaderboard {
  margin: 1rem 0;
}

.leaderboard-grid {
  opacity: 1;
  min-height: 0;

  @include for-tablet-landscape-up {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 10fr 1fr;
  }

  .leaderboard-column {
    height: 100%;
  }
}
</style>

<style lang="scss">
.ps__rail-y {
  border-radius: 16px;
  display: block;
  opacity: 0.6;
  right: 25px !important;
  width: 32px;

  @include for-tablet-landscape-up {
    display: block;
  }
}

.ps--active-y > .ps__rail-y {
  background-color: #edf2f4;
  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}

.ps {
  .ps__rail-y:hover,
  .ps__rail-y:focus,
  .ps__rail-y.ps--clicking {
    background-color: #edf2f4;
  }
}

.ps__thumb-y {
  background-color: #0c1725;
  border-radius: 9px;
  right: 7px;
  width: 18px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #0c1725;
  width: 19px;
}
</style>
