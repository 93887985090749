<template>
  <div class="loader">
    <svg
      class="labyrinth"
      viewBox="0 0 832.94 833.8"
    >
      <path
        class="track"
        d="M777.1,239.08c-56-113.26-164-199-298.18-220.13C259.44-15.68,53.62,135,19,354.45S133.81,780.21,353.3,814.84,779.42,699.19,814.05,479.71a398.19,398.19,0,0,0-19.18-198.38"
      />
      <path
        class="track"
        d="M748,303.29C712.19,199.19,628.31,113.11,514.72,79.92c-185.84-54.32-380.47,53-434.78,238.87s51.95,380.73,237.8,435.05,381.14-52.62,435.45-238.47a346.57,346.57,0,0,0,7.06-166"
      />
      <path
        class="track"
        d="M704.54,352.82c-20.23-90.44-82.36-170.28-174.29-208.66C379.84,81.38,207,153,144.21,303.42s7.92,323.37,158.33,386.16,323.77-8.45,386.56-158.86a291.35,291.35,0,0,0,22-129.29"
      />
      <path
        class="track"
        d="M653.9,400.2c-5.33-74.51-45.32-145.56-114-187.13-112.39-68-258.71-31.55-326.72,80.85s-32.3,258.7,80.1,326.71,259.08,31.93,327.09-80.47a234.51,234.51,0,0,0,31.95-91"
      />
      <path
        class="track"
        d="M595.73,445.63c9-56.29-8.42-116-52.25-158.83-71.72-70-186.77-68.33-256.81,3.38s-68.88,186.63,2.83,256.67,187,68.68,257-3a178.64,178.64,0,0,0,33.33-47.57"
      />

      <path
        class="progress"
        d="M777.1,239.08c-56-113.26-164-199-298.18-220.13C259.44-15.68,53.62,135,19,354.45S133.81,780.21,353.3,814.84,779.42,699.19,814.05,479.71a398.19,398.19,0,0,0-19.18-198.38"
        ref="progress1"
      />
      <path
        class="progress"
        d="M748,303.29C712.19,199.19,628.31,113.11,514.72,79.92c-185.84-54.32-380.47,53-434.78,238.87s51.95,380.73,237.8,435.05,381.14-52.62,435.45-238.47a346.57,346.57,0,0,0,7.06-166"
        ref="progress2"
      />
      <path
        class="progress"
        d="M704.54,352.82c-20.23-90.44-82.36-170.28-174.29-208.66C379.84,81.38,207,153,144.21,303.42s7.92,323.37,158.33,386.16,323.77-8.45,386.56-158.86a291.35,291.35,0,0,0,22-129.29"
        ref="progress3"
      />
      <path
        class="progress"
        d="M653.9,400.2c-5.33-74.51-45.32-145.56-114-187.13-112.39-68-258.71-31.55-326.72,80.85s-32.3,258.7,80.1,326.71,259.08,31.93,327.09-80.47a234.51,234.51,0,0,0,31.95-91"
        ref="progress4"
      />
      <path
        class="progress"
        d="M595.73,445.63c9-56.29-8.42-116-52.25-158.83-71.72-70-186.77-68.33-256.81,3.38s-68.88,186.63,2.83,256.67,187,68.68,257-3a178.64,178.64,0,0,0,33.33-47.57"
        ref="progress5"
      />
    </svg>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'Loader',

  mounted() {
    for (var i = 1; i <= 5; i++) {
      let tl = gsap.timeline({
        delay: Math.random(),
        repeat: 999,
        repeatDelay: 1,
      })

      tl.fromTo(
          this.$refs[`progress${i}`],
          {
            drawSVG: '0% 1%',
          },
          {
            drawSVG: '48% 52%',
            duration: 1,
            ease: 'power2.in',
          }
        )
        .to(
          this.$refs[`progress${i}`],
          {
            drawSVG: '99% 100%',
            duration: 1,
            ease: 'power2.out',
          }
        )
        .to(
          this.$refs[`progress${i}`],
          {
            drawSVG: '48% 52%',
            duration: 1,
            ease: 'power2.in',
          }
        )
        .to(
          this.$refs[`progress${i}`],
          {
            drawSVG: '0% 1%',
            duration: 1,
            ease: 'power2.out',
          }
        )
    }
  },
}
</script>

<style lang="scss" scoped>
.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
}

.labyrinth {
  position: absolute;
  top: 15%;
  width: 80px;
}

.track,
.progress {
  fill: none;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.track {
  stroke: #edf2f4;
  stroke-width: 28px;
}

.progress {
  stroke: #FD3E81;
  stroke-width: 21px;
}
</style>