export default {
  namespaced: true,

  state: {
    authenticated: !!localStorage.getItem('authenticated'),
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
  },

  actions: {
    async login ({ commit }, credentials) {
      if (credentials.password == 'mc@takethelead') {
        localStorage.setItem('authenticated', true)
        commit('SET_AUTHENTICATED', true)
      } else {
        throw 'Invalid login credentials'
      }
    },

    async logout ({ commit }) {
      localStorage.removeItem('authenticated')
      commit('SET_AUTHENTICATED', false)
    },
  },

  getters : {
    authenticated: state => state.authenticated,
  },
}
