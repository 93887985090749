<template>
  <svg width="55.72" height="71.18px" viewBox="0 0 55.72 71.18">
    <defs>
      <clipPath :id="`clip-${_uid}`" clipPathUnits="userSpaceOnUse">
        <path d="M15.61,63.89h0l-.06-.22Z" />
        <path d="M16.49,66.81c-.31-1-.61-2-.88-2.92a1.87,1.87,0,0,0-.07-.22c-.3-1.08-.59-2.14-.86-3.2-.32-1.23-.6-2.46-.87-3.66s-.47-2.29-.67-3.42A89.71,89.71,0,0,1,11.72,40l2.39,13.38.6,3.42.66,3.66.6,3.42Z" />
        <polygon points="26.35 63.89 26.35 66.81 16.49 66.81 15.97 63.89 26.35 63.89" />
        <polygon points="26.35 56.81 26.35 60.47 15.37 60.47 14.71 56.81 26.35 56.81" />
        <path d="M20,16.43a7.92,7.92,0,0,0,7.91,7.91v2.44a2.57,2.57,0,0,0-.32,5.13,1.71,1.71,0,0,0,.32,0v6.55A1.5,1.5,0,0,0,26.4,40h0V53.39H14.11L11.72,40h0a76.78,76.78,0,0,1,6.81-34.7h9.33V8.53A7.92,7.92,0,0,0,20,16.43Z" />
        <polygon points="26.35 53.39 26.35 56.81 14.71 56.81 14.11 53.39 26.35 53.39" />
        <polygon points="26.35 60.47 26.35 63.89 15.97 63.89 15.37 60.47 26.35 60.47" />
        <path d="M15.61,63.89h0l-.06-.22Z" />
        <path d="M40.11,63.89h0a.79.79,0,0,0,.06-.22Z" />
        <path d="M39.23,66.81c.31-1,.61-2,.88-2.92l.06-.22c.31-1.08.6-2.14.87-3.2.32-1.23.6-2.46.86-3.66s.48-2.29.68-3.42A89.71,89.71,0,0,0,44,40L41.61,53.39,41,56.81l-.66,3.66-.6,3.42Z" />
        <path d="M29.37,40V66.81h-3V40a1.51,1.51,0,0,1,3,0Z" />
        <polygon points="29.36 63.89 29.36 66.81 39.23 66.81 39.75 63.89 29.36 63.89" />
        <polygon points="29.36 56.81 29.36 60.47 40.35 60.47 41.01 56.81 29.36 56.81" />
        <path d="M35.76,16.43a7.92,7.92,0,0,1-7.9,7.91v2.44a2.57,2.57,0,0,1,0,5.13v6.55A1.51,1.51,0,0,1,29.37,40V53.39H41.61L44,40h0a76.78,76.78,0,0,0-6.81-34.7H27.86V8.53A7.92,7.92,0,0,1,35.76,16.43Z" />
        <polygon points="29.36 53.39 29.36 56.81 41.01 56.81 41.61 53.39 29.36 53.39" />
        <polygon points="29.36 60.47 29.36 63.89 39.75 63.89 40.35 60.47 29.36 60.47" />
        <path d="M40.11,63.89h0a.79.79,0,0,0,.06-.22Z" />
        <line x1="26.35" y1="66.81" x2="21.9" y2="66.81" />
        <rect x="27.86" y="8.52" width="9.33" height="25.66" />
        <rect x="18.53" y="8.52" width="9.33" height="25.66" />
        <path d="M21.26,0c-.08.13-.16.26-.23.4-.83,1.47-1.67,3.1-2.5,4.9h9.33V0Z" />
        <path d="M34.69.4,34.46,0h-6.6V5.3h9.33C36.36,3.5,35.52,1.87,34.69.4Z" />
        <path d="M14.41,71.18l2.08-4.37c-.31-1-.61-2-.88-2.92h0l-.06-.22c-.3-1.08-.59-2.14-.86-3.2-.32-1.23-.6-2.46-.87-3.66s-.47-2.29-.67-3.42A89.71,89.71,0,0,1,11.73,40h0S-2.88,50.48.51,71.18Z" />
        <path d="M55.21,71.18C58.59,50.48,44,40,44,40h0a89.71,89.71,0,0,1-1.42,13.38c-.2,1.13-.43,2.27-.68,3.42s-.54,2.43-.86,3.66c-.27,1.06-.56,2.12-.87,3.2a.79.79,0,0,1-.06.22h0c-.27,1-.57,1.93-.88,2.92l2.07,4.42Z" />
      </clipPath>
    </defs>

    <path class="cls-1" d="M15.61,63.89h0l-.06-.22Z" />
    <path class="cls-2" d="M16.49,66.81c-.31-1-.61-2-.88-2.92a1.87,1.87,0,0,0-.07-.22c-.3-1.08-.59-2.14-.86-3.2-.32-1.23-.6-2.46-.87-3.66s-.47-2.29-.67-3.42A89.71,89.71,0,0,1,11.72,40l2.39,13.38.6,3.42.66,3.66.6,3.42Z" />
    <polygon class="cls-2" points="26.35 63.89 26.35 66.81 16.49 66.81 15.97 63.89 26.35 63.89" />
    <polygon class="cls-2" points="26.35 56.81 26.35 60.47 15.37 60.47 14.71 56.81 26.35 56.81" />
    <path class="cls-3" d="M20,16.43a7.92,7.92,0,0,0,7.91,7.91v2.44a2.57,2.57,0,0,0-.32,5.13,1.71,1.71,0,0,0,.32,0v6.55A1.5,1.5,0,0,0,26.4,40h0V53.39H14.11L11.72,40h0a76.78,76.78,0,0,1,6.81-34.7h9.33V8.53A7.92,7.92,0,0,0,20,16.43Z" />
    <polygon class="cls-4" points="26.35 53.39 26.35 56.81 14.71 56.81 14.11 53.39 26.35 53.39" />
    <polygon class="cls-4" points="26.35 60.47 26.35 63.89 15.97 63.89 15.37 60.47 26.35 60.47" />
    <path class="cls-1" d="M15.61,63.89h0l-.06-.22Z" />
    <path class="cls-1" d="M40.11,63.89h0a.79.79,0,0,0,.06-.22Z" />
    <path class="cls-2" d="M39.23,66.81c.31-1,.61-2,.88-2.92l.06-.22c.31-1.08.6-2.14.87-3.2.32-1.23.6-2.46.86-3.66s.48-2.29.68-3.42A89.71,89.71,0,0,0,44,40L41.61,53.39,41,56.81l-.66,3.66-.6,3.42Z" />
    <path class="cls-4" d="M29.37,40V66.81h-3V40a1.51,1.51,0,0,1,3,0Z" />
    <polygon class="cls-4" points="29.36 63.89 29.36 66.81 39.23 66.81 39.75 63.89 29.36 63.89" />
    <polygon class="cls-4" points="29.36 56.81 29.36 60.47 40.35 60.47 41.01 56.81 29.36 56.81" />
    <path class="cls-4" d="M35.76,16.43a7.92,7.92,0,0,1-7.9,7.91v2.44a2.57,2.57,0,0,1,0,5.13v6.55A1.51,1.51,0,0,1,29.37,40V53.39H41.61L44,40h0a76.78,76.78,0,0,0-6.81-34.7H27.86V8.53A7.92,7.92,0,0,1,35.76,16.43Z" />
    <polygon class="cls-2" points="29.36 53.39 29.36 56.81 41.01 56.81 41.61 53.39 29.36 53.39" />
    <polygon class="cls-2" points="29.36 60.47 29.36 63.89 39.75 63.89 40.35 60.47 29.36 60.47" />
    <path class="cls-1" d="M40.11,63.89h0a.79.79,0,0,0,.06-.22Z" />
    <line class="cls-5" x1="26.35" y1="66.81" x2="21.9" y2="66.81" />
    <rect class="cls-4" x="27.86" y="8.52" width="9.33" height="25.66" />
    <rect class="cls-3" x="18.53" y="8.52" width="9.33" height="25.66" />
    <path class="cls-3" d="M21.26,0c-.08.13-.16.26-.23.4-.83,1.47-1.67,3.1-2.5,4.9h9.33V0Z" />
    <path class="cls-6" d="M34.69.4,34.46,0h-6.6V5.3h9.33C36.36,3.5,35.52,1.87,34.69.4Z" />
    <path class="cls-7" d="M14.41,71.18l2.08-4.37c-.31-1-.61-2-.88-2.92h0l-.06-.22c-.3-1.08-.59-2.14-.86-3.2-.32-1.23-.6-2.46-.87-3.66s-.47-2.29-.67-3.42A89.71,89.71,0,0,1,11.73,40h0S-2.88,50.48.51,71.18Z" />
    <path class="cls-4" d="M55.21,71.18C58.59,50.48,44,40,44,40h0a89.71,89.71,0,0,1-1.42,13.38c-.2,1.13-.43,2.27-.68,3.42s-.54,2.43-.86,3.66c-.27,1.06-.56,2.12-.87,3.2a.79.79,0,0,1-.06.22h0c-.27,1-.57,1.93-.88,2.92l2.07,4.42Z" />

    <g :clip-path="`url(#clip-${_uid})`">
      <polygon ref="glint" class="glint" points="0 92.81 92.81 0 92.89 21.38 0.17 114.1 0 92.81" />
    </g>
  </svg>
</template>

<script>
import mojs from '@mojs/core'

export default {
  name: 'MiniBadgeOverall',

  mounted() {
    const moveCurve = mojs.easing.bezier(0.8 , 0 , 0.2 , 1)

    new mojs.Html({
      delay: Math.round(Math.random() * 2000) + 2000,
      duration: 1200,
      easing: moveCurve,
      el: this.$refs.glint,
      repeat: 999,
      x: 0,
      y: { '-170%' : '50%' },
    }).play()
  }
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #404e5c;
}

.cls-2 {
  fill: #e7ae11;
}

.cls-3 {
  fill: #f9e298;
}

.cls-4 {
  fill: #fdcc20;
}

.cls-5 {
  fill: none;
}

.cls-6 {
  fill: #fedb61;
}

.cls-7 {
  fill: #f9d04e;
}

.glint {
  fill: #fff;
  opacity: 0.55;
}
</style>