<template>
  <svg width="72px" height="71.18px" viewBox="0 -5 72 71.18">
    <defs>
      <clipPath :id="`clip-${_uid}`" clipPathUnits="userSpaceOnUse">
        <rect x="27.05" y="63.19" width="17.88" height="2.55" />
        <path d="M37.5,55.75v-4.6h-3v4.6a7.45,7.45,0,0,1-7.43,7.46H44.93A7.45,7.45,0,0,1,37.5,55.75Z" />
        <path d="M32.17,48.3a7.39,7.39,0,0,0,1.12,4.06A3.31,3.31,0,0,0,36,54a3.31,3.31,0,0,0,2.7-1.68,7.39,7.39,0,0,0,1.12-4.06Z" />
        <path d="M19.81,38a4.42,4.42,0,0,1-3.24,3.12l-.79-2.69a1.72,1.72,0,0,0,1.31-1.12A3.3,3.3,0,0,0,16,34.5c-.76-.77-1.66-1.54-2.65-2.38L13.14,32C7.91,27.47,0,20.68,0,6.07V4.66H11.74V6.07a6.35,6.35,0,0,0,2.41,5,2.57,2.57,0,0,0,1.42.55v2.81a4.24,4.24,0,0,1-1.11-.15c-2.6-.65-4.89-3.54-5.41-6.84H2.84C3.31,19.82,10,25.58,15,29.84l.32.28c1,.84,1.88,1.62,2.65,2.4A6,6,0,0,1,19.81,38Z" />
        <path d="M72,4.66V6.07c0,14.61-7.91,21.4-13.14,25.9-1.05.9-2,1.72-2.82,2.53a3.26,3.26,0,0,0-1.12,2.82,1.68,1.68,0,0,0,1.31,1.12l-.8,2.69A4.42,4.42,0,0,1,52.17,38,5.92,5.92,0,0,1,54,32.52c.85-.86,1.87-1.73,3-2.68,4.94-4.26,11.66-10,12.13-22.37H62.93c-.52,3.3-2.81,6.19-5.41,6.84a4.24,4.24,0,0,1-1.11.15V11.65a2.57,2.57,0,0,0,1.42-.55h0a6.25,6.25,0,0,0,2.38-4.42q0-.3,0-.6V4.66Z" />
        <path d="M58.15,3.09c0,2-.06,3.91-.17,5.81q-.06,1.11-.15,2.19h0c-.08,1.09-.19,2.15-.31,3.21C55.44,32.62,48.39,46.86,39.64,50a10.68,10.68,0,0,1-3.65.65l-.57,0-.3,0-.47,0-.46-.07a3.73,3.73,0,0,1-.46-.09l-.43-.1-.61-.18L32.34,50a12.7,12.7,0,0,1-1.57-.69L30.15,49c-.17-.1-.34-.2-.51-.32a14.08,14.08,0,0,1-1.32-1c-.2-.15-.39-.32-.59-.48t0,0l-.56-.51a25.81,25.81,0,0,1-2.8-3.17L23.93,43l-.12-.18c-.51-.72-1-1.49-1.48-2.3a.43.43,0,0,0,0-.07c-.17-.28-.33-.57-.49-.85l-.47-.88c-.16-.3-.32-.6-.47-.91-.33-.67-.65-1.35-1-2.06l-.36-.83c-.42-1-.81-2-1.19-3.09,0,0,0,0,0,0-.41-1.14-.79-2.32-1.13-3.54a81.57,81.57,0,0,1-2.75-13.91c-.12-1.06-.23-2.12-.31-3.21-.21-2.6-.32-5.28-.32-8Z" />
        <path d="M58.15,3.09c0,2-.06,3.91-.17,5.81q-.06,1.11-.15,2.19h0c-.08,1.09-.19,2.15-.31,3.21C55.44,32.62,48.39,46.86,39.64,50a10.68,10.68,0,0,1-3.65.65l-.57,0-.3,0-.47,0-.46-.07a3.73,3.73,0,0,1-.46-.09l-.43-.1-.61-.18L32.34,50a12.7,12.7,0,0,1-1.57-.69L30.15,49c-.17-.1-.34-.2-.51-.32a14.08,14.08,0,0,1-1.32-1c-.2-.15-.39-.32-.59-.48t0,0l-.56-.51a25.81,25.81,0,0,1-2.8-3.17l-.4-.56-.13-.17c-.51-.72-1-1.49-1.48-2.3a.43.43,0,0,0,0-.07c-.17-.28-.33-.57-.49-.85l-.47-.88c-.16-.3-.32-.6-.47-.91-.33-.67-.65-1.35-1-2.06l-.36-.83c-.42-1-.81-2-1.19-3.09,2.49,3.95,5.36,6.82,8.48,8.27a10.93,10.93,0,0,0,4.68,1.08c8.45,0,15.84-10.29,19.57-25.33A85.88,85.88,0,0,0,53.22,3.09Z" />
        <rect x="13" width="45.98" height="3.09" />
      </clipPath>
    </defs>

    <rect class="cls-1" x="27.05" y="63.19" width="17.88" height="2.55" />
    <path class="cls-2" d="M37.5,55.75v-4.6h-3v4.6a7.45,7.45,0,0,1-7.43,7.46H44.93A7.45,7.45,0,0,1,37.5,55.75Z" />
    <path class="cls-1" d="M32.17,48.3a7.39,7.39,0,0,0,1.12,4.06A3.31,3.31,0,0,0,36,54a3.31,3.31,0,0,0,2.7-1.68,7.39,7.39,0,0,0,1.12-4.06Z" />
    <path class="cls-3" d="M19.81,38a4.42,4.42,0,0,1-3.24,3.12l-.79-2.69a1.72,1.72,0,0,0,1.31-1.12A3.3,3.3,0,0,0,16,34.5c-.76-.77-1.66-1.54-2.65-2.38L13.14,32C7.91,27.47,0,20.68,0,6.07V4.66H11.74V6.07a6.35,6.35,0,0,0,2.41,5,2.57,2.57,0,0,0,1.42.55v2.81a4.24,4.24,0,0,1-1.11-.15c-2.6-.65-4.89-3.54-5.41-6.84H2.84C3.31,19.82,10,25.58,15,29.84l.32.28c1,.84,1.88,1.62,2.65,2.4A6,6,0,0,1,19.81,38Z" />
    <path class="cls-2" d="M72,4.66V6.07c0,14.61-7.91,21.4-13.14,25.9-1.05.9-2,1.72-2.82,2.53a3.26,3.26,0,0,0-1.12,2.82,1.68,1.68,0,0,0,1.31,1.12l-.8,2.69A4.42,4.42,0,0,1,52.17,38,5.92,5.92,0,0,1,54,32.52c.85-.86,1.87-1.73,3-2.68,4.94-4.26,11.66-10,12.13-22.37H62.93c-.52,3.3-2.81,6.19-5.41,6.84a4.24,4.24,0,0,1-1.11.15V11.65a2.57,2.57,0,0,0,1.42-.55h0a6.25,6.25,0,0,0,2.38-4.42q0-.3,0-.6V4.66Z" />
    <path class="cls-2" d="M58.15,3.09c0,2-.06,3.91-.17,5.81q-.06,1.11-.15,2.19h0c-.08,1.09-.19,2.15-.31,3.21C55.44,32.62,48.39,46.86,39.64,50a10.68,10.68,0,0,1-3.65.65l-.57,0-.3,0-.47,0-.46-.07a3.73,3.73,0,0,1-.46-.09l-.43-.1-.61-.18L32.34,50a12.7,12.7,0,0,1-1.57-.69L30.15,49c-.17-.1-.34-.2-.51-.32a14.08,14.08,0,0,1-1.32-1c-.2-.15-.39-.32-.59-.48t0,0l-.56-.51a25.81,25.81,0,0,1-2.8-3.17L23.93,43l-.12-.18c-.51-.72-1-1.49-1.48-2.3a.43.43,0,0,0,0-.07c-.17-.28-.33-.57-.49-.85l-.47-.88c-.16-.3-.32-.6-.47-.91-.33-.67-.65-1.35-1-2.06l-.36-.83c-.42-1-.81-2-1.19-3.09,0,0,0,0,0,0-.41-1.14-.79-2.32-1.13-3.54a81.57,81.57,0,0,1-2.75-13.91c-.12-1.06-.23-2.12-.31-3.21-.21-2.6-.32-5.28-.32-8Z" />
    <path class="cls-4" d="M58.15,3.09c0,2-.06,3.91-.17,5.81q-.06,1.11-.15,2.19h0c-.08,1.09-.19,2.15-.31,3.21C55.44,32.62,48.39,46.86,39.64,50a10.68,10.68,0,0,1-3.65.65l-.57,0-.3,0-.47,0-.46-.07a3.73,3.73,0,0,1-.46-.09l-.43-.1-.61-.18L32.34,50a12.7,12.7,0,0,1-1.57-.69L30.15,49c-.17-.1-.34-.2-.51-.32a14.08,14.08,0,0,1-1.32-1c-.2-.15-.39-.32-.59-.48t0,0l-.56-.51a25.81,25.81,0,0,1-2.8-3.17l-.4-.56-.13-.17c-.51-.72-1-1.49-1.48-2.3a.43.43,0,0,0,0-.07c-.17-.28-.33-.57-.49-.85l-.47-.88c-.16-.3-.32-.6-.47-.91-.33-.67-.65-1.35-1-2.06l-.36-.83c-.42-1-.81-2-1.19-3.09,2.49,3.95,5.36,6.82,8.48,8.27a10.93,10.93,0,0,0,4.68,1.08c8.45,0,15.84-10.29,19.57-25.33A85.88,85.88,0,0,0,53.22,3.09Z" />
    <rect class="cls-1" x="13" width="45.98" height="3.09" />

    <g :clip-path="`url(#clip-${_uid})`">
      <polygon ref="glint" class="glint" points="0 92.81 92.81 0 92.89 21.38 0.17 114.1 0 92.81" />
    </g>
  </svg>
</template>

<script>
import mojs from '@mojs/core'

export default {
  name: 'MiniBadgeOverall',

  mounted() {
    const moveCurve = mojs.easing.bezier(0.8 , 0 , 0.2 , 1)

    new mojs.Html({
      delay: Math.round(Math.random() * 2000) + 2000,
      duration: 1200,
      easing: moveCurve,
      el: this.$refs.glint,
      repeat: 999,
      x: 0,
      y: { '-170%' : '40%' },
    }).play()
  }
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #e7ae11;
}

.cls-2 {
  fill: #fedb61;
}

.cls-3 {
  fill: #f9e298;
}

.cls-4 {
  fill: #fdcc20;
}

.glint {
  fill: #fff;
  opacity: 0.55;
}
</style>