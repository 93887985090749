<template>
  <div class="rules">
    <div class="rules-bg">
      <Header />
      <div class="rules-grid container">
        <div class="content">
          <div class="pill">Rules of The Game</div>
          <p>
            Knowing that everyone loves a healthy competition, “Take the Lead” rewards local markets<sup>*1</sup> for
            progressing through the key stages of prospecting, to pitch and to win. Markets’ progress will be tracked
            monthly across the world.
          </p>
          <p>
            The higher the value of your engaged prospects and converted pitches, the more points you score. The highest
            scores will be awarded for taking a prospect all the way through to a won client.
          </p>
          <p>
            We have created an algorithm to level the playing field so that any market can win
            <strong>irrespective of size</strong>. The value of your opportunities is indexed against the total annual
            billings of your market for last year. The more you win, the more points you get. The bigger the win, the
            bigger the points<sup>*2</sup>.
          </p>
          <p>
            Any win worth more than 5% of the overall billings for your market (recorded last year in Cartesis) will
            generate an additional 10 points for every 1% over the 5%. Your market scores will be awarded based on the
            information you update in MCDab.
          </p>
          <img src="@/assets/images/rules.svg" />
          <p class="smaller"><sup>*1</sup> The competition does not include affiliate markets</p>
          <p class="smaller"><sup>*2</sup> The competition does not include centrally led led wins</p>
          <div class="pill pill--margin-top">Prizes</div>
          <p>
            We will be giving away two amazing prizes a quarter to markets for Best Pitcher and Best Prospector, there
            will also be an overall winner for Best Pitcher and Best Prospector, including prizes for New Business
            Heroes awarded throughout the year. This will be shared across the business and highlighted in our “Take the
            Lead” newsletter.
          </p>
        </div>
      </div>
      <div class="new-business-hero-grid container">
        <div class="badge">
          <img src="@/assets/images/new-business-hero-static.svg" />
        </div>
        <div class="content">
          <div class="pill">New Business Hero</div>
          <p>
            Nominate one person who, you consider, has made an outstanding contribution in new business. You can also
            nominate a unique partnership that you have developed either with another WPP agency or another story that
            made you win that particular pitch and/or prospect. Ensure you
            <router-link class="pink semi-bold" to="/nominate">
              submit your entries
            </router-link>
            before the last week of the month to be considered.
          </p>
        </div>
      </div>
      <div class="mcdab-grid container">
        <div class="content">
          <div class="pill">Powered by McDab</div>
          <p>
            MCDab is one of the most important tools available to transform new business and existing client data into
            actionable insight. It is crucial that all data for your market is correct and continuously up to date.
            Identify opportunities, fuel your pipeline, and track results by taking advantage of the live system
            <a
              class="pink semi-bold"
              href="https://insidemedia.sharepoint.com/sites/McDab/Pages/Index.aspx#/"
              target="_blank"
              @click="$gtag.event('Click', { event_category: 'External link', event_label: 'McDab' })"
            >
              here </a
            >.
          </p>
          <p>
            We love a little competition, so come on Take the Lead! If you have questions or queries about the scoring,
            please
            <a class="pink semi-bold break" href="mailto:EMCMCDabContact.WW@essencemediacom.com">contact us here</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Rules',

  components: {
    Header,
  },
}
</script>

<style lang="scss" scoped>
.rules {
  background-image: url(../assets/images/background-nominate.png);
  background-position: 50% 0;
  background-size: cover;

  .rules-bg {
    padding-bottom: 4rem;

    @include for-tablet-landscape-up {
      background-image: url(../assets/images/background-rules.svg);
      background-position: 100% 100%;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.rules-grid {
  margin-top: 2rem;

  @include for-tablet-landscape-up {
    display: grid;
    gap: 1rem;
    grid-template-columns: 3fr 8fr 1fr;
  }

  .content {
    grid-column: 2/2;
  }
}

.new-business-hero-grid {
  margin-top: 4rem;

  @include for-tablet-landscape-up {
    display: grid;
    gap: 1rem;
    grid-template-columns: 4fr 5fr 1fr 10fr 4fr;
  }

  .badge {
    display: none;
    grid-column: 2/2;

    @include for-tablet-landscape-up {
      display: block;
    }
  }

  .content {
    grid-column: 4/4;
  }
}

.mcdab-grid {
  margin-top: 4rem;

  @include for-tablet-landscape-up {
    display: grid;
    gap: 1rem;
    grid-template-columns: 3fr 6fr 3fr;
  }

  .content {
    grid-column: 2/2;
  }
}

.pill {
  background-color: #fd3e81;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem 1rem;

  @include for-tablet-landscape-up {
    font-size: 20px;
  }

  &--margin-top {
    margin-top: 1em;
  }
}
</style>
