import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Leaderboard from '../views/Leaderboard.vue'
import Nominate from '../views/Nominate.vue'
import Rules from '../views/Rules.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Leaderboard',
    component: Leaderboard,
  },
  {
    path: '/nominate',
    name: 'Nominate',
    component: Nominate,
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.name)
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.name} | EssenceMediaCom Take the Lead`
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/authenticated']) {
    next({ path: '/' })
  } else if (to.matched.some(record => record.meta.hideForAuth) && store.getters['auth/authenticated']) {
    next({ path: '/' })
  } else {
    next()
  }
})

export default router
