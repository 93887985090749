<template>
  <div
    :class="`leaderboard-entry ${relativeQuarter} ${searchResult != entry.Country || 'highlight'} ${isInActiveRegion}`"
  >
    <div class="position-change">
      <div class="position">{{ entry.Position }}</div>
      <div
        class="change"
        :class="{ animate: entry.PositionChange }"
        :style="{ 'animation-delay': `${Math.random() * 5}s` }"
      >
        <img v-if="!entry.PositionChange" class="icon" src="@/assets/images/position-no-change.svg" />
        <template v-else>
          <img class="icon" src="@/assets/images/position-change.svg" :class="{ inverted: entry.PositionChange < 0 }" />
          {{ Math.abs(entry.PositionChange) }}
        </template>
      </div>
    </div>
    <span class="score">{{ entry.Total }}</span>
    <span class="country">
      <div class="flag" :style="{ 'background-image': flag(entry.Country) }" />
      {{ entry.Country }}
    </span>
    <div class="pitch-prospect">
      <div class="pitch">
        <div class="value">
          {{ entry.Pitch + entry.OrganicGrowth }}
          <div class="label">Pitch</div>
        </div>
      </div>
      <div class="prospect">
        <div class="value">
          {{ entry.Prospect }}
          <div class="label">Prospecting</div>
        </div>
      </div>
    </div>
    <div v-if="activeQuarter == 'Annual'" class="badges">
      <div v-if="entry.OverallBadges" class="badge">
        <div class="count" v-tooltip="`Overall Winner`">
          {{ entry.OverallBadges }}
        </div>
        <MiniBadgeOverall />
      </div>
      <div v-if="entry.ProspectBadges" class="badge">
        <div class="count" v-tooltip="`Best Prospector`">
          {{ entry.ProspectBadges }}
        </div>
        <MiniBadgeProspect />
      </div>
      <div v-if="entry.PitchBadges" class="badge">
        <div class="count" v-tooltip="`Best Pitch Performer`">
          {{ entry.PitchBadges }}
        </div>
        <MiniBadgePitch />
      </div>
    </div>
  </div>
</template>

<script>
import MiniBadgeOverall from '@/components/MiniBadgeOverall.vue'
import MiniBadgePitch from '@/components/MiniBadgePitch.vue'
import MiniBadgeProspect from '@/components/MiniBadgeProspect.vue'
import StringMixin from '@/mixins/StringMixin'

export default {
  name: 'LeaderboardEntry',

  components: {
    MiniBadgeOverall,
    MiniBadgePitch,
    MiniBadgeProspect,
  },

  mixins: [StringMixin],

  props: {
    activeQuarter: [Number, String],
    activeRegion: String,
    currentQuarter: Number,
    entry: Object,
    searchResult: String,
  },

  computed: {
    relativeQuarter() {
      if (this.activeQuarter == this.currentQuarter - 1) {
        return 'last'
      } else if (this.activeQuarter == this.currentQuarter) {
        return 'current'
      }

      return 'annual'
    },

    isInActiveRegion() {
      if (this.activeRegion == 'All Regions' || this.activeRegion == this.entry.Region) {
        return 'is-in-active-region'
      }

      return ''
    },
  },

  methods: {
    flag(country) {
      try {
        return 'url(' + require(`@/assets/flags/${this.removeWhitespace(country)}.png`) + ')'
      } catch (e) {
        return 'none'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.leaderboard-entry {
  background-color: #edf2f4;
  border-radius: 34px;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 3fr 1fr;
  margin-bottom: 0.5rem;
  transition: all 300ms ease; /* cubic-bezier(0.1 , 0.9 , 0.2 , 1); Decelerate (enter) */

  @include for-tablet-landscape-up {
    grid-template-columns: 1fr 1fr 5fr 3fr;
  }

  &.annual {
    grid-template-columns: 3fr 2fr 6fr 1fr;

    @include for-tablet-landscape-up {
      grid-template-columns: 1fr 1fr 4fr 2fr 2fr;
      padding-right: 1rem;
    }
  }

  &:not(.is-in-active-region) {
    display: none;
  }
}

.position-change {
  align-items: center;
  background-color: #61e1e0;
  border-radius: 34px 0 0 34px;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  text-align: center;

  .position {
    font-size: 32px;
    font-weight: bold;

    @include for-tablet-landscape-up {
      font-size: 45px;
    }
  }

  .change {
    font-size: 12px;

    &.animate {
      animation: tada;
      animation-duration: 10s;
      animation-iteration-count: 999;
    }
  }

  .icon {
    display: block;
    width: 17px;

    &.inverted {
      transform: scaleY(-1);
    }
  }
}

.score {
  align-items: center;
  color: #fd3e81;
  display: flex;
  font-size: 21px;
  font-weight: bold;

  @include for-tablet-landscape-up {
    font-size: 40px;
  }
}

.country {
  align-items: center;
  color: #0d172d;
  display: flex;
  font-size: 18px;
  font-weight: 200;

  @include for-tablet-landscape-up {
    font-size: 40px;
  }

  .flag {
    background-size: cover;
    display: inline-block;
    height: 30px;
    margin-right: 1rem;
    width: 30px;
  }
}

.pitch-prospect {
  display: none;

  @include for-tablet-landscape-up {
    align-items: center;
    color: #0d172d;
    display: grid;
    font-size: 30px;
    font-weight: 600;
    grid-template-columns: 1fr 1fr;
  }

  .pitch,
  .prospect {
    line-height: 1em;

    .value {
      padding-left: 2.5rem;
    }

    .label {
      font-size: 12px;
      font-weight: 400;
      line-height: 1em;
    }
  }

  .prospect {
    .value {
      border-left: 3px solid #61e1e0;
    }
  }
}

.badges {
  display: none;

  @include for-tablet-landscape-up {
    display: flex;
  }

  .badge {
    position: relative;

    .count {
      font-size: 24px;
      font-weight: bold;
      height: 100%;
      padding-top: 10%;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    svg {
      display: block;
      height: 100%;
    }
  }
}

.annual {
  .position {
    @include for-tablet-landscape-up {
      margin-left: 1rem;
    }
  }

  .position-change {
    background-color: #792359;
  }

  .pitch,
  .prospect {
    .value {
      padding-left: 1rem;
    }
  }

  .prospect {
    .value {
      border-left-color: #792359;
    }
  }
}

.last {
  .position-change {
    background-color: #faa916;
  }

  .prospect {
    .value {
      border-left-color: #faa916;
    }
  }
}

.highlight {
  background-color: #fd3e81;

  .position-change {
    background-color: #0d1726;
  }

  .score,
  .country,
  .pitch-prospect {
    color: #fff;
  }

  .pitch-prospect {
    .prospect {
      .value {
        border-color: #fff;
      }
    }
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  1%,
  2% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  3%,
  5%,
  7%,
  9% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  4%,
  6%,
  8% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  10%,
  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}
</style>
