import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueGtag from 'vue-gtag'
import VTooltip from 'v-tooltip'
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(DrawSVGPlugin, ScrollToPlugin)

import '@/assets/scss/app.scss'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: 'G-ZBQ6TN145J' }
}, router)

Vue.use(VTooltip)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
