<template>
  <div class="leaderboard-filter" :class="`${1 == currentQuarter && 'is-first-quarter'}`">
    <transition mode="out-in" name="slide-fade">
      <div v-if="activeQuarter == currentQuarter - 1" :key="activeQuarter" class="title">
        Last quarter’s leaderboard {{ new Date().getFullYear() }}
      </div>
      <div v-else-if="activeQuarter == currentQuarter" :key="activeQuarter" class="title">
        This quarter’s leaderboard {{ new Date().getFullYear() }}
      </div>
      <div v-else :key="activeQuarter" class="title">Year to date leaderboard {{ new Date().getFullYear() }}</div>
    </transition>
    <div class="filter" :class="{ wait: this.animationCount > 0 || this.loading }">
      <div :class="`highlight ${highlightClass}`" />
      <div
        v-if="currentQuarter > 1"
        class="quarter"
        :class="{ active: activeQuarter == currentQuarter - 1 }"
        @click="updateActiveQuarter(currentQuarter - 1)"
      >
        {{ quarters[currentQuarter - 1] }}
      </div>
      <div
        class="quarter"
        :class="{ active: activeQuarter == currentQuarter }"
        @click="updateActiveQuarter(currentQuarter)"
      >
        {{ quarters[currentQuarter] }}
      </div>
      <div class="quarter" :class="{ active: activeQuarter == 'Annual' }" @click="updateActiveQuarter('Annual')">
        Year to date
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeaderboardFilter',

  props: {
    activeQuarter: [Number, String],
    animationCount: Number,
    currentQuarter: Number,
    loading: Boolean,
  },

  data: () => ({
    quarters: [
      null, // Make array 1-indexed and match API
      'Quarter 1',
      'Quarter 2',
      'Quarter 3',
      'Quarter 4',
    ],
  }),

  computed: {
    highlightClass() {
      if (this.activeQuarter == this.currentQuarter - 1) {
        return 'left'
      } else if (this.activeQuarter == this.currentQuarter) {
        return this.currentQuarter == 1 ? 'left' : 'center'
      }

      return 'right'
    },
  },

  methods: {
    updateActiveQuarter(quarter) {
      if (this.animationCount == 0 && !this.loading) {
        this.$emit('update:activeQuarter', quarter)

        let humanQuarter = ''
        if (this.activeQuarter == this.currentQuarter - 1) {
          humanQuarter = 'Last quarter'
        } else if (this.activeQuarter == this.currentQuarter) {
          humanQuarter = 'This quarter'
        } else {
          humanQuarter = 'YTD'
        }

        this.$gtag.event('Click', {
          event_category: 'Leaderboard filter',
          event_label: humanQuarter,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1);
}

.slide-fade-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

.title {
  font-size: 14px;
  font-weight: bold;
  margin: 1rem 0 0;
  text-align: center;

  @include for-tablet-landscape-up {
    font-size: 21px;
    margin: 0;
  }
}

.filter {
  background-color: #000;
  border-radius: 20.5px;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  position: relative;

  .highlight {
    background-color: #fd3e81;
    border-radius: 22.5px;
    height: 110%;
    left: 16.75%;
    margin: -0.5% 0 0 -18%;
    position: absolute;
    transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1);
    width: 36%;

    &.center {
      left: 50%;
    }

    &.right {
      left: 83.25%;
    }
  }
}

.quarter {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 9px;
  font-weight: 300;
  justify-content: center;
  padding: 6px 0;
  position: relative;
  text-align: center;
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1);
  width: 33.33%;
  z-index: 1;

  @include for-tablet-landscape-up {
    font-size: 19px;
  }

  &.active {
    font-size: 10px;
    font-weight: bold;

    @include for-tablet-landscape-up {
      font-size: 19px;
    }
  }
}

.filter.wait .quarter {
  cursor: wait;
}

.leaderboard-filter {
  &.is-first-quarter {
    .filter {
      .highlight {
        width: 50%;

        &.right {
          left: 68.5%;
        }
      }
    }

    .quarter {
      width: 50%;
    }
  }
}
</style>
