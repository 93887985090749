export default {
  namespaced: true,

  state: {
    ignoreWelcome: !!localStorage.getItem('ignoreWelcome'),
  },

  mutations: {
    SET_IGNORE_WELCOME (state, value) {
      state.ignoreWelcome = value
    },
  },

  actions: {
    ignoreForSession ({ commit }) {
      commit('SET_IGNORE_WELCOME', true)
    },

    ignoreForever ({ commit }) {
      localStorage.setItem('ignoreWelcome', true)
      commit('SET_IGNORE_WELCOME', true)
    },
  },

  getters : {
    ignoreWelcome: state => state.ignoreWelcome,
  },
}
