<template>
  <div
    class="new-business-hero-badge overflow"
    :style="{ transform: `scaleY(${scale})` }"
  >
    <div
      ref="badge"
      class="badge"
      :class="{ success }"
      :style="{ transform: `scaleX(${scale})` }"
    >
      <div
        class="circle-rings"
        ref="circleRings"
      />
      <img
        class="icon"
        ref="icon"
        src="@/assets/images/new-business-hero.svg"
      />
      <div
        class="plaque"
        ref="plaque"
      >
        <div
          class="country"
          ref="country"
        >
          New Business Hero<br />of the month
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fitty from 'fitty'
import mojs from '@mojs/core'

export default {
  name: 'Badge',

  props: {
    delay: Number,
    success: Boolean,
  },

  data: () => ({
    enterTimeline: null,
    leaveTimeline: null,
    scale: 1,
  }),

  mounted() {
    window.addEventListener('resize', this.resizeHandler)

    // mo.js setup
    const accelerateCurve = mojs.easing.bezier(0.7 , 0 , 1 , 0.5)
    const decelerateCurve = mojs.easing.bezier(0.1 , 0.9 , 0.2 , 1)

    class Star extends mojs.CustomShape {
      getShape() {
        return '<path d="M5.51132201,34.7776271 L33.703781,32.8220808 L44.4592855,6.74813038 C45.4370587,4.30369752 47.7185293,3 50,3 C52.2814707,3 54.5629413,4.30369752 55.5407145,6.74813038 L66.296219,32.8220808 L94.488678,34.7776271 C99.7034681,35.1035515 101.984939,41.7850013 97.910884,45.2072073 L75.9109883,63.1330483 L82.5924381,90.3477341 C83.407249,94.4217888 80.4739296,97.6810326 77.0517236,97.6810326 C76.0739505,97.6810326 74.9332151,97.3551083 73.955442,96.7032595 L49.8370378,81.8737002 L26.044558,96.7032595 C25.0667849,97.3551083 23.9260495,97.6810326 22.9482764,97.6810326 C19.3631082,97.6810326 16.2668266,94.4217888 17.4075619,90.3477341 L23.9260495,63.2960105 L2.08911601,45.2072073 C-1.98493875,41.7850013 0.296531918,35.1035515 5.51132201,34.7776271 Z" />'
      }
    }
    mojs.addShape('star', Star)

    this.enterTimeline = new mojs.Timeline({
      delay: this.delay,
    })

    this.leaveTimeline = new mojs.Timeline({
      onComplete: () => {
        setTimeout(() => {
          // Reset: Circle core and rings
          setTimeout(() => {
            new mojs.Html({
              el: this.$refs.circleRings,
              scale: 1,
              y: 0,
            })
          }, 100)

          this.enterTimeline.play()
        }, 1000)
      }
    })

    // fitty setup
    fitty(this.$refs.plaque, {
      maxSize: 28
    })

    // Leave: Circle core and rings
    this.leaveTimeline.add(
      new mojs.Html({
        duration: 800,
        easing: 'back.in',
        el: this.$refs.circleRings,
        scale: { 1 : 0 },
        y: { 0 : 50 },
      })
    )

    // Leave: Icon
    this.leaveTimeline.add(
      new mojs.Html({
        angleZ: { 0 : -45 },
        duration: 800,
        easing: 'back.in',
        el: this.$refs.icon,
        scale: { .9 : 0 },
        y: { 0 : 50 },
      })
    )

    // Leave: Country
    this.leaveTimeline.add(
      new mojs.Html({
        duration: 800,
        easing: accelerateCurve,
        el: this.$refs.country,
        y: { 0 : 40 },
      })
    )

    // Enter: Inner ring
    this.enterTimeline.add(
      new mojs.Shape({
        angle: 90,
        className: 'stroke',
        delay: 200,
        duration: 2400,
        fill: 'none',
        opacity: .6,
        parent: this.$refs.circleRings,
        radius: 92.5,
        shape: 'circle',
        strokeDasharray: this.getDashValue(92.5, 100),
        strokeDashoffset: { [this.getDashValue(92.5, 100)] : 0, easing: decelerateCurve },
        strokeWidth: 25,
        top: '60%',
      })
    )

    // Enter: Middle ring
    this.enterTimeline.add(
      new mojs.Shape({
        angle: 90,
        className: 'stroke',
        delay: 300,
        duration: 2400,
        fill: 'none',
        opacity: .4,
        parent: this.$refs.circleRings,
        radius: 117.5,
        shape: 'circle',
        strokeDasharray: this.getDashValue(117.5, 100),
        strokeDashoffset: { [this.getDashValue(117.5, 100)] : 0, easing: decelerateCurve },
        strokeWidth: 25,
        top: '60%',
      })
    )

    // Enter: Outer ring
    this.enterTimeline.add(
      new mojs.Shape({
        angle: 90,
        className: 'stroke',
        delay: 400,
        duration: 2400,
        fill: 'none',
        opacity: .2,
        parent: this.$refs.circleRings,
        radius: 142.5,
        shape: 'circle',
        strokeDasharray: this.getDashValue(142.5, 100),
        strokeDashoffset: { [this.getDashValue(142.5, 100)] : 0, easing: decelerateCurve },
        strokeWidth: 25,
        top: '60%',
      })
    )

    // Enter: Burst
    for (var delay = 400; delay <= 700; delay += 150) {
      this.enterTimeline.add(
        new mojs.Burst({
          children: {
            className: 'fill',
            radius: 4,
            opacity: { 1 : 0 },
          },
          count: 24,
          parent: this.$refs.badge,
          radius: { 90 : 200 },
          timeline: {
            easing: decelerateCurve,
            delay: delay,
            speed: .5,
          },
          top: '60%',
        })
      )
    }

    // Enter: Stars
    for (var i = 0; i <= 1; i++) {
      this.enterTimeline.add(
        new mojs.Burst({
          children: {
            angle: { 90 : 0 },
            degreeShift:  'rand(-50, 50)',
            fill: i ? '#FD3E81' : '#FEDB61',
            radius: 12,
            opacity: { 1 : 0 },
            shape: 'star',
          },
          className: 'stars',
          count: 4,
          parent: this.$refs.badge,
          radius: { 90 : 200 },
          timeline: {
            easing: decelerateCurve,
            delay: delay,
            speed: .5,
          },
          top: '60%',
        })
      )
    }

    // Enter: Circle core
    this.enterTimeline.add(
      new mojs.Shape({
        duration: 300,
        className: 'fill',
        parent: this.$refs.circleRings,
        radius: { 0 : 80, easing: decelerateCurve },
        shape: 'circle',
        top: '60%',
      })
    )

    // Enter: Icon
    this.enterTimeline.add(
      new mojs.Html({
        angleZ: { [-45] : 0 },
        delay: 800,
        duration: 1200,
        easing: 'elastic.out',
        el: this.$refs.icon,
        scale: { 0 : .9 },
      })
    )

    // Enter: Country
    this.enterTimeline.add(
      new mojs.Html({
        delay: 800,
        duration: 3200,
        easing: decelerateCurve,
        el: this.$refs.country,
        y: { 90 : 0 },
      })
    )

    this.enterTimeline.play()
    this.resizeHandler()
  },

  methods: {
    getDashValue(radius, percentage) {
      const circumference = 2 * 3.1415927 * radius,
            percentageAsDecimal = percentage / 100
      
      return circumference * percentageAsDecimal
    },

    resizeHandler() {
      this.scale = Math.min(1, this.$refs.badge.clientWidth / 340) // natural width
    },
  },
  
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
}
</script>

<style lang="scss">
.new-business-hero-badge {
  .fill svg ellipse {
    fill: #FAA916;
    transition: fill 500ms cubic-bezier(0.8 , 0 , 0.2 , 1);
  }

  .stroke svg ellipse {
    stroke: #FAA916;
    transition: stroke 500ms cubic-bezier(0.8 , 0 , 0.2 , 1);
  }

  .badge.success {
    .fill svg ellipse {
      fill: #61E1E0;
    }
    
    .stroke svg ellipse {
      stroke: #61E1E0;
    }
  }
}
</style>

<style lang="scss" scoped>
.new-business-hero-badge {
  margin: -3rem 0 3rem;
  overflow: hidden;
  pointer-events: none;
}

.badge {
  align-items: center;
  display: flex;
  height: 362px;
  justify-content: center;
  position: relative;
}

.circle-rings {
  height: 100%;
  position: absolute;
  width: 100%;
}

.icon {
  height: 205px;
  top: 20.5%;
  position: absolute;
  z-index: 2;
}

.plaque {
  background-color: #fff;
  border-radius: 53px;
  bottom: 0;
  font-weight: bold;
  line-height: 1.15em;
  max-width: 100%;
  min-width: 250px;
  overflow: hidden;
  padding: 25px 50px;
  position: absolute;
  text-align: center;
  z-index: 1;
}
</style>