<template>
  <svg width="63.33" height="71.18px" viewBox="0 0 63.33 71.18">
    <defs>
      <clipPath :id="`clip-${_uid}`" clipPathUnits="userSpaceOnUse">
        <polygon points="23.05 71.18 20.77 28.61 0 14.67 15.43 71.18 23.05 71.18" />
        <polygon points="47.9 71.18 63.33 14.67 42.56 28.61 40.27 71.18 47.9 71.18" />
        <path d="M63.06,13.88a1.41,1.41,0,0,0-1.1-.53H39.87L35.21,0H28.12L23.46,13.35H1.37A1.34,1.34,0,0,0,0,14.67a1.3,1.3,0,0,0,.56,1.05L18.5,28.25,10.13,48.7A1.3,1.3,0,0,0,11,50.35a1.41,1.41,0,0,0,1.23-.19L31.66,37.63,51.07,50.16A1.42,1.42,0,0,0,53,49.88a1.26,1.26,0,0,0,.2-1.18L44.83,28.25,62.77,15.72A1.28,1.28,0,0,0,63.06,13.88Z" />
        <path d="M47.39,71.18l5.8-22.48c-2.12,1.46-15.11-7.45-15.11-7.45L34.9,71.18Z" />
        <path d="M29.69,71.18,25.24,41.25S11.33,51,10.21,49l5.73,22.18Z" />
        <polygon points="15.87 47.83 23.05 71.18 19.19 45.77 15.87 47.83" />
        <polygon points="47.36 47.83 39.5 71.18 44.04 45.77 47.36 47.83" />
      </clipPath>
    </defs>

    <polygon class="cls-1" points="23.05 71.18 20.77 28.61 0 14.67 15.43 71.18 23.05 71.18" />
    <polygon class="cls-1" points="47.9 71.18 63.33 14.67 42.56 28.61 40.27 71.18 47.9 71.18" />
    <path class="cls-2" d="M63.06,13.88a1.41,1.41,0,0,0-1.1-.53H39.87L35.21,0H28.12L23.46,13.35H1.37A1.34,1.34,0,0,0,0,14.67a1.3,1.3,0,0,0,.56,1.05L18.5,28.25,10.13,48.7A1.3,1.3,0,0,0,11,50.35a1.41,1.41,0,0,0,1.23-.19L31.66,37.63,51.07,50.16A1.42,1.42,0,0,0,53,49.88a1.26,1.26,0,0,0,.2-1.18L44.83,28.25,62.77,15.72A1.28,1.28,0,0,0,63.06,13.88Z" />
    <path class="cls-3" d="M47.39,71.18l5.8-22.48c-2.12,1.46-15.11-7.45-15.11-7.45L34.9,71.18Z" />
    <path class="cls-3" d="M29.69,71.18,25.24,41.25S11.33,51,10.21,49l5.73,22.18Z" />
    <polygon class="cls-1" points="15.87 47.83 23.05 71.18 19.19 45.77 15.87 47.83" />
    <polygon class="cls-1" points="47.36 47.83 39.5 71.18 44.04 45.77 47.36 47.83" />

    <g :clip-path="`url(#clip-${_uid})`">
      <polygon ref="glint" class="glint" points="0 92.81 92.81 0 92.89 21.38 0.17 114.1 0 92.81" />
    </g>
  </svg>
</template>

<script>
import mojs from '@mojs/core'

export default {
  name: 'MiniBadgeOverall',

  mounted() {
    const moveCurve = mojs.easing.bezier(0.8 , 0 , 0.2 , 1)

    new mojs.Html({
      delay: Math.round(Math.random() * 2000) + 2000,
      duration: 1200,
      easing: moveCurve,
      el: this.$refs.glint,
      repeat: 999,
      x: 0,
      y: { '-170%' : '40%' },
    }).play()
  }
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #e7ae11;
}

.cls-2 {
  fill: #fedb61;
}

.cls-3 {
  fill: #f9e298;
}

.cls-4 {
  fill: #fdcc20;
}

.glint {
  fill: #fff;
  opacity: 0.55;
}
</style>