<template>
  <form class="leaderboard-search" @submit.prevent="">
    <autocomplete
      placeholder="Market finder"
      ref="search"
      :search="search"
      @input="showReset = !!$refs.search.value"
      @submit="handleSubmit"
    />
    <button v-if="showReset" class="close-icon" type="reset" @click="reset" />
  </form>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'
import StringMixin from '@/mixins/StringMixin'

import '@trevoreyre/autocomplete-vue/dist/style.css'

export default {
  name: 'LeaderboardSearch',

  components: {
    Autocomplete,
  },

  data: () => ({
    showReset: false,
  }),

  mixins: [StringMixin],

  props: {
    activeQuarter: [Number, String],
    leaderboard: Object,
    searchValue: String,
  },

  computed: {
    activeQuarterCountries() {
      return this.leaderboard[this.activeQuarter].map(entry => entry.Country)
    },
  },

  methods: {
    reset() {
      this.$refs.search.setValue('')
      this.$emit('update:activeRegion', 'All Regions')
      this.$emit('update:searchResult', '')
    },

    handleSubmit(result) {
      this.$emit('update:activeRegion', 'All Regions')

      this.$nextTick(() => {
        this.$emit('update:searchResult', result)
      })
    },

    search(input) {
      if (input.length < 1) {
        return []
      }

      return this.activeQuarterCountries.filter(country => country.toLowerCase().startsWith(input.toLowerCase()))
    },
  },

  watch: {
    searchValue(val) {
      if (!val) {
        this.$refs.search.setValue('')
      }
    },
  },
}
</script>

<style lang="scss">
.leaderboard-search {
  position: relative;
}

.close-icon {
  background-color: transparent;
  background-image: url(../assets/images/close-grey.svg);
  border: none;
  cursor: pointer;
  height: 12px;
  outline: none;
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
  width: 10px;
}

.autocomplete-input {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 8px;
  padding-top: 8px;
  position: relative;

  @include for-tablet-landscape-up {
    font-size: 16px;
  }

  &:not(:valid) ~ .close-icon {
    display: none;
  }
}

.autocomplete-result-list {
  border: none;
}

[data-position='below'] {
  .autocomplete-input[aria-expanded='true'] {
    border-radius: 20px 20px 0 0;
  }

  .autocomplete-result-list {
    border-radius: 0 0 20px 20px;
  }
}
</style>
