<template>
  <div class="welcome container">
    <div class="modal">
      <div class="modal-bg">
        <img class="close" src="@/assets/images/close-pink.svg" @click="close" />
        <div class="modal-grid">
          <div class="modal-content">
            <div class="lockup">
              <Logo />
            </div>
            <div class="blurb">
              <p class="pink larger">Welcome to <span class="semi-bold">Take the Lead.</span></p>
              <p>
                Take the Lead is our market-led prospecting and pitching competition.<br />
                It’s a live leaderboard comparing results and rewarding success.
              </p>
              <p>
                You get points for your team’s work creating and converting prospects and for winning pitches. You’ll
                find the Rules of the Game on the homepage.
              </p>
              <p>
                It is people who win pitches and so we will be recognising and rewarding New Biz Heroes from the
                markets. Make sure to nominate your local market heroes by following the link on the homepage. New
                Business is the heart of our agency and you are all the drivers of our growth.
              </p>
              <p class="pink">Have fun and good luck!</p>
            </div>
            <div class="mcdab">
              <a
                href="https://insidemedia.sharepoint.com/sites/McDab/Pages/Index.aspx#/"
                target="_blank"
                @click="$gtag.event('Click', { event_category: 'External link', event_label: 'McDab' })"
              >
                Powered by <span class="semi-bold pink">MCDab</span>
              </a>
            </div>
            <div class="ignore" @click="ignore = !ignore">
              <div class="tick-container">
                <img class="tick" :class="{ active: ignore }" src="@/assets/images/tick.svg" />
              </div>
              <div class="label">Do not show again</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'

export default {
  name: 'Welcome',

  components: {
    Logo,
  },

  data: () => ({
    ignore: false,
  }),

  methods: {
    close() {
      if (this.ignore) {
        this.$store.dispatch('welcome/ignoreForever')
      } else {
        this.$store.dispatch('welcome/ignoreForSession')
      }

      this.$emit('update:welcome', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome {
  background-image: url(../assets/images/background.png);
  background-position: 50% 0;
  background-size: cover;
  left: 0;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;

  @include for-tablet-landscape-up {
    align-items: center;
    bottom: 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 10fr 1fr;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.modal {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(237, 242, 244, 1) 78%);
  border-radius: 37px;
  grid-column: 2/2;
  position: relative;
  z-index: 1;

  .modal-bg {
    background-color: #fff;
    background-image: url(../assets/images/background-welcome.svg);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 37px;

    @include for-desktop-up {
      background-color: transparent;
    }
  }

  .modal-grid {
    padding-bottom: 4rem;
    padding-top: 4rem;

    @include for-tablet-landscape-up {
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr 18fr 1fr;
    }
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 27px;
}

.modal-content {
  grid-column: 2/2;
}

.lockup {
  margin: 0 auto 1rem;
  width: 150px;

  @include for-tablet-landscape-up {
    margin: 0;
  }
}

.blurb {
  padding: 0 2rem;
  text-align: center;

  p:first-child {
    margin: 0 0 3rem;
  }

  .larger {
    font-size: 28px;
  }
}

.mcdab,
.ignore {
  padding: 0 2rem;

  @include for-tablet-landscape-up {
    padding: 0;
  }
}

.mcdab {
  margin-top: 4rem;
}

.ignore {
  color: #fd3e81;
  cursor: pointer;
  display: flex;
  margin-top: 1rem;

  .tick-container {
    border: 1px solid #fd3e81;
    border-radius: 50%;
  }

  .tick {
    display: block;
    opacity: 0;
    transition: all 300ms cubic-bezier(0.8, 0, 0.2, 1);
    width: 27px;

    &.active {
      opacity: 1;
    }
  }

  .label {
    margin-left: 0.8rem;
  }
}
</style>
