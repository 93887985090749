<template>
  <div class="home">
    <transition name="fade">
      <Welcome v-if="welcome" @update:welcome="welcome = $event" />
    </transition>
    <div class="top-grid container">
      <div class="logo-intro">
        <transition mode="out-in" name="slide-fade">
          <Logo v-if="!loading" />
        </transition>
        <transition mode="out-in" name="slide-fade">
          <div v-if="!loading && activeQuarter == currentQuarter" class="intro" :key="activeQuarter">
            <p>
              Take the Lead is a live competition between markets on a level playing field. Points are gained for
              success in prospecting, pitching and organic client growth with prizes being awarded on a quarterly basis.
            </p>
            <p class="nominate-share">
              <router-link to="/nominate">Nominate a New Business Hero</router-link>
            </p>
          </div>
          <div v-else-if="!loading" class="intro" :key="activeQuarter">
            <p>
              It is our job to learn from each other, connect with other markets and integrate best practice. Do you
              have pitch winning work to share across the region? Upload great content to the New Business and Marketing
              community.
            </p>
            <p class="nominate-share">
              <a
                href="https://insidemedia.sharepoint.com/sites/MCM-Global-Community-BizDevMarketing/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fsites%2FMCM%2DGlobal%2DCommunity%2DBizDevMarketing%2FShared%20Documents%2FGeneral%2FTake%20the%20Lead&FolderCTID=0x0120006E9C4B73FBF8A84280A375CB721C9AE2&OR=Teams%2DHL&CT=1674487051488&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzAxMDEwMDkwMyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                target="_blank"
                @click="$gtag.event('Click', { event_category: 'External link', event_label: 'Share and Reapply' })"
              >
                Share and Reapply
              </a>
            </p>
          </div>
        </transition>
      </div>
      <div class="nav-container">
        <Nav />
      </div>
      <div class="best-pitch">
        <Badge
          size="small"
          type="pitch"
          :activeQuarter="activeQuarter"
          :animationCount="animationCount"
          :countries="pitchWinners"
          :currentQuarter="currentQuarter"
          :delay="1300"
          :loading="loading"
          @update:animationCount="animationCount = $event"
        />
      </div>
      <div class="overall-winner">
        <Badge
          type="overall"
          :activeQuarter="activeQuarter"
          :animationCount="animationCount"
          :countries="overallWinners"
          :currentQuarter="currentQuarter"
          :delay="1000"
          :loading="loading"
          @update:animationCount="animationCount = $event"
        />
      </div>
      <div class="best-prospect">
        <Badge
          size="small"
          type="prospect"
          :activeQuarter="activeQuarter"
          :animationCount="animationCount"
          :countries="prospectWinners"
          :currentQuarter="currentQuarter"
          :delay="1600"
          :loading="loading"
          @update:animationCount="animationCount = $event"
        />
      </div>
      <router-link class="rules-link" to="/rules">
        Rules of the Game
      </router-link>
    </div>
    <div class="leaderboard-filter-grid container-fluid">
      <LeaderboardFilter
        :activeQuarter="activeQuarter"
        :animationCount="animationCount"
        :currentQuarter="currentQuarter"
        :loading="loading"
        @update:activeQuarter="activeQuarter = $event"
      />
    </div>
    <div class="leaderboard-search-grid container">
      <LeaderboardRegion
        :activeRegion="activeRegion"
        :leaderboard="leaderboard"
        @update:activeRegion="activeRegion = $event"
        @update:searchResult="searchResult = $event"
      />
      <LeaderboardSearch
        :activeQuarter="activeQuarter"
        :leaderboard="leaderboard"
        :searchValue="searchResult"
        @update:activeRegion="activeRegion = $event"
        @update:searchResult="searchResult = $event"
      />
    </div>
    <Leaderboard
      ref="leaderboardComponent"
      :activeQuarter="activeQuarter"
      :activeRegion="activeRegion"
      :animationCount="animationCount"
      :currentQuarter="currentQuarter"
      :leaderboard="leaderboard"
      :loading="loading"
      :searchResult="searchResult"
      @update:animationCount="animationCount = $event"
      @scrollTo="scrollTo"
    />
  </div>
</template>

<script>
import AzureWorker from '@/workers/Azure.worker'
import Badge from '@/components/Badge.vue'
import { gsap } from 'gsap'
import Leaderboard from '@/components/Leaderboard.vue'
import LeaderboardFilter from '@/components/LeaderboardFilter.vue'
import LeaderboardRegion from '@/components/LeaderboardRegion.vue'
import LeaderboardSearch from '@/components/LeaderboardSearch.vue'
import Logo from '@/components/Logo.vue'
import Nav from '@/components/Nav.vue'
import StringMixin from '@/mixins/StringMixin'
import Welcome from '@/components/Welcome.vue'

export default {
  name: 'Home',

  components: {
    Badge,
    Leaderboard,
    LeaderboardFilter,
    LeaderboardRegion,
    LeaderboardSearch,
    Logo,
    Nav,
    Welcome,
  },

  mixins: [StringMixin],

  data: function() {
    const QuarterOfTheYear = date => {
      var month = date.getMonth() + 1
      return Math.ceil(month / 3)
    }

    return {
      activeRegion: 'All Regions',
      activeQuarter: QuarterOfTheYear(new Date()),
      animationCount: 0,
      currentQuarter: QuarterOfTheYear(new Date()),
      leaderboard: {},
      loading: true,
      searchResult: '',
      welcome: !this.$store.getters['welcome/ignoreWelcome'],
      worker: new AzureWorker(),
    }
  },

  computed: {
    pitchWinners() {
      return this.leaderboard.Badges ? this.leaderboard.Badges[this.activeQuarter].Pitch : []
    },

    prospectWinners() {
      return this.leaderboard.Badges ? this.leaderboard.Badges[this.activeQuarter].Prospect : []
    },

    overallWinners() {
      return this.leaderboard.Badges ? this.leaderboard.Badges[this.activeQuarter].Overall : []
    },
  },

  mounted() {
    document.addEventListener('mousewheel', this.controlScroll)

    this.worker.onmessage = ({ data }) => {
      this.leaderboard = data

      this.animationCount = this.leaderboard.Annual.length // The leaderboard is in a state of animation during load
      this.loading = false
    }

    if (!this.welcome) {
      this.worker.postMessage(null)
    }
  },

  watch: {
    activeQuarter() {
      if (this.searchResult) {
        const delay = 1800 // This doesn't work? -> parseFloat(getComputedStyle(document.querySelector('.list-enter-active')).transitionDuration) * 1000

        setTimeout(() => {
          this.scrollTo(this.searchResult)
        }, delay * 2)
      }
    },

    searchResult() {
      this.scrollTo(this.searchResult)
    },

    welcome() {
      if (!this.welcome) {
        this.worker.postMessage(null)
      }
    },
  },

  methods: {
    controlScroll(e) {
      if (!this.loading) {
        const evt = window.event || e,
          delta = evt.detail ? evt.detail * -120 : evt.wheelDelta

        this.$refs.leaderboardComponent.$refs.leaderboard.$el.scroll({
          top: this.$refs.leaderboardComponent.$refs.leaderboard.$el.scrollTop - delta,
        })
      }
    },

    scrollTo(result) {
      const y = result
        ? this.$refs.leaderboardComponent.$refs[`${this.activeQuarter}-${this.removeWhitespace(result)}`][0].$el.dataset
            .index *
          (this.$refs.leaderboardComponent.$refs[
            `${this.activeQuarter}-${this.removeWhitespace(result)}`
          ][0].$el.getBoundingClientRect().height +
            parseFloat(
              getComputedStyle(
                this.$refs.leaderboardComponent.$refs[`${this.activeQuarter}-${this.removeWhitespace(result)}`][0].$el,
              ).marginBottom,
            ))
        : 0

      gsap.to(this.$refs.leaderboardComponent.$refs.leaderboard.$el, {
        duration: 0.8,
        scrollTo: y,
        ease: 'power2.out',
      })
    },
  },

  destroyed() {
    document.removeEventListener('mousewheel', this.controlScroll)
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1) 1500ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1) 300ms;
}

.slide-fade-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

.home {
  background-image: url(../assets/images/background.png);
  background-position: 100% 0;
  background-size: cover;
  display: flex;
  flex-flow: column;
  height: 100vh;

  @include for-tablet-landscape-up {
    background-position: 50% 0;
  }
}

.top-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 3.5fr 2fr;
  max-height: 86vw;
  position: relative;

  @include for-tablet-portrait-up {
    grid-template-rows: 2fr 3.5fr 2fr;
  }

  @include for-tablet-landscape-up {
    gap: 1rem;
    grid-template-columns: 4fr 4fr 1fr 6fr 1fr 4fr 4fr;
    grid-template-rows: 1fr;
  }

  .logo-intro {
    grid-column: 1/5;
    margin-top: 2rem;

    @include for-tablet-portrait-up {
      grid-column: 1/3;
    }

    @include for-tablet-landscape-up {
      grid-column: 1/1;
    }

    p {
      font-size: 12px;

      &.nominate-share {
        font-size: 21px;
      }
    }
  }

  .intro {
    display: none;

    @include for-tablet-landscape-up {
      display: block;
    }
  }

  .best-pitch {
    grid-column: 1/5;
    grid-row: 3/3;
    position: relative;

    @include for-tablet-landscape-up {
      grid-column: 2/2;
      grid-row: 1/1;
      margin-top: 4rem;
    }

    .overflow {
      position: absolute;
      top: -161%;
      width: 100%;

      @include for-tablet-landscape-up {
        position: static;
      }
    }
  }

  .overall-winner {
    grid-column: 4/10;
    grid-row: 2/2;
    position: relative;

    @include for-tablet-landscape-up {
      grid-column: 4/4;
      grid-row: 1/1;
      margin-top: 0;
    }

    .overflow {
      position: absolute;
      top: -49%;
      width: 100%;

      @include for-tablet-landscape-up {
        position: static;
      }
    }
  }

  .best-prospect {
    grid-column: 9/13;
    grid-row: 3/3;
    position: relative;

    @include for-tablet-landscape-up {
      grid-column: 6/6;
      grid-row: 1/1;
      margin-top: 4rem;
    }

    .overflow {
      position: absolute;
      top: -161%;
      width: 100%;

      @include for-tablet-landscape-up {
        position: static;
      }
    }
  }

  .nav-container {
    display: flex;
    grid-column: 6/13;
    justify-content: flex-end;
    margin-top: 2rem;
    position: relative;

    @include for-tablet-landscape-up {
      grid-column: 7/7;
    }
  }

  .rules-link {
    display: none;

    @include for-tablet-landscape-up {
      display: block;
      position: absolute;
      right: 7rem;
      top: 3rem;
    }
  }
}

.leaderboard-filter-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 10fr 1fr;

  @include for-tablet-portrait-up {
    margin-top: 1rem;
  }

  @include for-tablet-landscape-up {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -2rem;
  }

  .leaderboard-filter {
    grid-column: 2/2;
  }
}

.leaderboard-search-grid {
  display: none;

  @include for-tablet-portrait-up {
    display: grid;
    gap: 1rem;
    grid-template-columns: 9fr 3fr;
    margin-top: 1rem;
  }

  @include for-tablet-landscape-up {
    grid-template-columns: 1fr 2fr 6fr 2fr 1fr;
    margin-top: -1rem;
  }

  .leaderboard-region {
    grid-column: 2/3;
  }

  .leaderboard-search {
    grid-column: 4/5;
  }
}

.leaderboard-grid {
  min-height: 0; /* Auto-fill remaining height */
}
</style>
