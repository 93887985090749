<template>
  <div class="header container">
    <div class="logo-intro">
      <router-link to="/">
        <Logo />
      </router-link>
    </div>
    <div class="nav-container">
      <Nav />
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'Header',

  components: {
    Logo,
    Nav,
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr;

  @include for-tablet-landscape-up {
    grid-template-columns: 1fr 5fr;
  }

  .logo-intro {
    margin-top: 2rem;
  }

  .nav-container {
    justify-content: flex-end;
    display: flex;
    margin-top: 2rem;
    position: relative;
  }

  .rules-link {
    display: none;

    @include for-tablet-landscape-up {
      display: block;
      margin: 1rem 2rem 0 0;
    }
  }
}
</style>
