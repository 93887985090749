<template>
  <div class="leaderboard-region" :class="{ active }">
    <div v-if="active" class="mask" @click="active = false"></div>

    <div class="relative">
      <div class="label" @click="active = !active">
        <div class="label-region">
          <img class="pin" src="@/assets/images/pin.svg" />
          {{ activeRegion }}
        </div>
        <img class="down-arrow" src="@/assets/images/down-arrow.svg" />
      </div>
      <div v-if="active" class="options">
        <div class="option" @click="updateActiveRegion('All Regions')">
          <img class="pin" src="@/assets/images/pin.svg" />
          All Regions
        </div>
        <div class="option" v-for="region in leaderboard.Regions" :key="region" @click="updateActiveRegion(region)">
          <img class="pin" src="@/assets/images/pin.svg" />
          {{ region }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeaderboardRegion',

  props: {
    activeRegion: String,
    leaderboard: Object,
  },

  data: () => ({
    active: false,
  }),

  methods: {
    updateActiveRegion(region) {
      this.$emit('update:activeRegion', region)
      this.$emit('update:searchResult', '')
      this.active = false
    },
  },
}
</script>

<style lang="scss" scoped>
.leaderboard-region {
  font-size: 14px;
  font-weight: 300;

  @include for-tablet-landscape-up {
    font-size: 16px;
  }
}

.mask {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.relative {
  position: relative;
}

.down-arrow {
  transition: transform 200ms cubic-bezier(0.8, 0, 0.2, 1);
}

.label {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 8px 12px;
  width: 100%;
}

.label-region {
  display: flex;
}

.options {
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  position: absolute;
  width: 100%;
  z-index: 1;
}

.option {
  cursor: pointer;
  display: flex;
  padding: 8px 16px 8px 12px;
  transition: background-color 200ms;

  &:hover {
    background-color: #f0f0f0;
  }
}

.pin {
  margin-right: 8px;
  width: 24px;
}

.down-arrow {
  width: 12px;
}

.leaderboard-region.active {
  .label {
    border-radius: 20px 20px 0 0;
  }

  .down-arrow {
    transform: rotate(180deg);
  }
}
</style>
