<template>
  <div class="nav">
    <div class="burger" :class="{ active }" @click="active = !active">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <transition name="slide-fade">
      <div v-if="active" class="menu">
        <router-link class="link" to="/">
          Leaderboard
        </router-link>
        <router-link class="link" to="/rules"> Rules of<br />the Game </router-link>
        <router-link class="link" to="/nominate"> Nominate a<br />New Business Hero </router-link>
        <a
          class="link"
          href="https://insidemedia.sharepoint.com/sites/MCM-Global-Community-BizDevMarketing/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fsites%2FMCM%2DGlobal%2DCommunity%2DBizDevMarketing%2FShared%20Documents%2FGeneral%2FTake%20the%20Lead&FolderCTID=0x0120006E9C4B73FBF8A84280A375CB721C9AE2&OR=Teams%2DHL&CT=1674487051488&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzAxMDEwMDkwMyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
          target="_blank"
          @click="$gtag.event('Click', { event_category: 'External link', event_label: 'Share and Reapply' })"
        >
          Share and<br />Reapply
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Nav',

  data: () => ({
    active: false,
  }),
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.burger {
  cursor: pointer;
  height: 30px;
  margin: 1rem 1rem 0 0;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 30px;
  z-index: 3;

  span {
    background: #0d1726;
    border-radius: 2px;
    display: block;
    height: 3px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%;

    &:nth-child(1) {
      top: 4px;
    }

    &:nth-child(2) {
      top: 14px;
    }

    &:nth-child(3) {
      top: 24px;
    }
  }

  &.active {
    span {
      background: #fd3e81;

      &:nth-child(1) {
        top: 12px;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        left: 30px;
        opacity: 0;
      }

      &:nth-child(3) {
        top: 12px;
        transform: rotate(-135deg);
      }
    }
  }
}

.menu {
  background-color: #fff;
  border-radius: 33px;
  box-shadow: -10px 10px rgba(188, 205, 211, 0.23);
  font-size: 18px;
  font-weight: 200;
  line-height: 1.15rem;
  padding: 0 1rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: all 500ms cubic-bezier(0.8, 0, 0.2, 1);
  width: 250px;
  z-index: 2;

  .link {
    cursor: pointer;
    display: block;
    margin: 1.5rem 0 2rem;

    &.router-link-exact-active,
    &:hover {
      color: #fd3e81;
      font-weight: 600;
    }
  }
}
</style>
